<template>
  <v-col
    class="relative d-flex justify-end align-center py-1 cursor-pointer"
    cols="12"
    md="6"
    lg="6"
    sm="6"
    @mouseenter="showOnHover = true"
    @mouseleave="showOnHover = false"
  >
    <input
      class="modal-input cursor-pointer my-1 disabled"
      type="text"
      placeholder="Name"
      :value="banner.text"
      :ref="`inputBanner-${index}`"
      @keyup.enter="enterInput(`inputBanner-${index}`)"
      @change="changeValue($event, index, banner._id)"
      @blur="enterInput(`inputBanner-${index}`)"
    />
    <div
      v-if="showOnHover"
      class="action-icons d-flex justify-end align-center"
    >
      <!-- <img
        @click.stop="editBanner(`inputBanner-${index}`, index)"
        src="@/assets/images/icons/edit.svg"
        alt="edit"
      /> -->
      <img
        @click.stop="deleteBanner(banner._id)"
        src="@/assets/images/icons/del.svg"
        alt="del"
      />
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    banner: {
      type: Object,
      default: null,
    },
    index: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      activeTab: false,
      showOnHover: false,
      bannerText: null,
    };
  },
  methods: {
    editBanner(el) {
      this.$refs[el].removeAttribute("disabled");
      this.$refs[el].focus();
    },
    async deleteBanner(id) {
      this.$emit("deleteBanner", id);
    },
    enterInput(el) {
      this.$refs[el].setAttribute("disabled", true);
    },
    changeValue(event, index, id) {
      this.$emit("changeValue", { event, index, id });
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
}
</style>