<template>
  <div class="py-3 px-5">
    <div class="d-flex align-center justify-space-between">
      <span class="white--text" v-if="item.length > 1"
        >{{ item.length }} items Selected
      </span>
      <span class="white--text" v-if="item.length == 1"
        >Selected Item ({{ item[0].title }})</span
      >
      <div class="d-flex align-center">
        <v-tooltip
          top
          v-for="(icon, i) in filteredIcons"
          :key="i"
          :color="icon.type == 'deletePermanentDialog' ? 'red' : ''"
        >
          <template v-slot:activator="{ on, attrs }">
            <img
              v-bind="attrs"
              v-on="on"
              class="cursor-pointer mr-3"
              @click="clickHandler(icon.type)"
              :src="icon.url"
            />
          </template>
          <span>{{ icon.title }}</span>
        </v-tooltip>
      </div>
    </div>

    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <delete-dialog
        @close="deleteDialog = false"
        @getData="$emit('updateData')"
        @deleteHandler="deleteHandler"
        :urlApi="productsUrl"
        :multiDelete="true"
      ></delete-dialog>
    </v-dialog>

    <v-dialog
      v-model="deletePermanentDialog"
      max-width="400"
      content-class="white"
    >
      <delete-dialog
        @close="deletePermanentDialog = false"
        @getData="$emit('updateData')"
        @deleteHandler="deletePermanentHandler"
        :urlApi="productsUrl"
        :multiDelete="true"
      ></delete-dialog>
    </v-dialog>

    <!-- assignDialog -->
    <v-dialog v-model="assignDialog" max-width="450px">
      <assign-item-group
        @close="assignDialog = false"
        @update="closeUpdate"
        :key="assignDialog"
        :groups="groups"
        :items="item"
      ></assign-item-group>
    </v-dialog>

    <!-- duplicateDialog -->
    <v-dialog v-model="duplicateDialog" max-width="450px">
      <duplicate-item
        @close="duplicateDialog = false"
        @update="closeUpdate"
        :key="duplicateDialog"
        :item="item[0]"
      ></duplicate-item>
    </v-dialog>
  </div>
</template>

<script>
import AssignItemGroup from "../../../components/dialogs/AssignItemGroup.vue";
import DuplicateItem from "../../../components/dialogs/Duplicate.vue";
export default {
  components: { AssignItemGroup, DuplicateItem },
  props: {
    item: {
      type: [Array, Object],
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    multiItems: {
      type: Array,
      default: () => [],
    },
    selectedTab: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    icons: [
      {
        url: require("@/assets/images/icons/copy-white.svg"),
        type: "duplicate",
        title: "Duplicate",
      },
      {
        url: require("@/assets/images/icons/folder.svg"),
        type: "folder",
        title: "Move to group",
      },
      {
        url: require("@/assets/images/icons/delete-white-outlined.svg"),
        type: "delete",
        title: "Delete Product",
      },
      {
        url: require("@/assets/images/icons/delete-white-outlined.svg"),
        type: "deletePermanent",
        title: "Delete Permanent Product",
      },
      {
        url: require("@/assets/images/icons/close-white.svg"),
        type: "close",
        title: "Close",
      },
    ],
    deleteDialog: false,
    deletePermanentDialog: false,
    assignDialog: false,
    duplicateDialog: false,
  }),
  computed: {
    filteredIcons() {
      if (this.selectedTab == 3) {
        return this.icons.filter((icon) => icon.type == "deletePermanent");
      } else {
        return this.icons.filter((icon) => icon.type != "deletePermanent");
      }
    },
    productsUrl() {
      return process.env.VUE_APP_PRODUCTS;
    },
  },
  methods: {
    clickHandler(type) {
      if (type == "close") {
        this.$emit("close");
      }
      if (type == "duplicate") {
        this.duplicateDialog = true;
      }
      if (type == "delete") {
        this.deleteDialog = true;
      }
      if (type == "deletePermanent") {
        this.deletePermanentDialog = true;
      }
      if (type == "folder") {
        this.assignDialog = true;
      }
    },
    closeUpdate() {
      this.assignDialog = false;
      this.duplicateDialog = false;

      this.$emit("close");
      this.$emit("update");
    },
    async deleteHandler() {
      let products = this.item.map((item) => item._id);
      let { data } = await this.$axios.delete(`products/multi`, {
        data: { products },
      });
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Deleted Successfully",
          color: "success",
        });
        this.deleteDialog = false;
        this.$emit("close");
        this.$emit("update");
      } else {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "error",
        });
      }
    },
    async deletePermanentHandler() {
      let products = this.item.map((item) => item._id);
      let { data } = await this.$axios.delete(`products/multi/permanent`, {
        data: { products },
      });
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Deleted Successfully",
          color: "success",
        });
        this.deletePermanentDialog = false;
        this.$emit("close");
        this.$emit("update");
      } else {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "error",
        });
      }
    },
  },
  created() {
    if (this.multiItems.length > 0) {
      // //console.log("is multi");
    } else {
      // //console.log("is single");
    }
  },
};
</script>

<style></style>
