<template>
  <div>
    <div
      v-if="showForm"
      class="text-center bg-red-400 my-5"
      style="border: 1px solid #f00; height: 50px"
    >
      momen
    </div>
    <button class="primary" @click="saveData">Save</button>
    <slot name="row" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showForm: true,
    };
  },
  methods: {
    saveData() {
      //console.log("saveData");
      this.showForm = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
