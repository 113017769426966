<template>
  <div class="date-picker-dialog">
    <!-- <div class="d-flex mb-5">
      <v-btn
        class="date-picker-btn flex-grow-1"
        :class="[openedDatePicker === 0 ? 'active' : '']"
        @click="openDatePicker(0)"
      >
        <v-icon>mdi-calendar</v-icon>
        Start Date
      </v-btn>
      <v-icon class="mx-5">mdi-arrow-right</v-icon>
      <v-btn
        class="date-picker-btn flex-grow-1"
        :class="[openedDatePicker === 1 ? 'active' : '']"
        @click="openDatePicker(1)"
      >
        <v-icon>mdi-calendar</v-icon>
        End Date
      </v-btn>
    </div> -->
    <div class="row w-100 mx-auto date-pickers" :key="datePickerKey">
      <v-col cols="12" lg="6">
        <VueCtkDateTimePicker
          id="DateTimePicker"
          v-model="startDate"
          format="YYYY-MM-DD HH:mm"
          inline
          :noShortcuts="true"
          no-button-now
          color="#0195A9"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <VueCtkDateTimePicker
          id="DateTimePicker"
          v-model="endDate"
          format="YYYY-MM-DD HH:mm"
          inline
          :noShortcuts="true"
          no-button-now
          :min-date="minimumEndDate"
          color="#0195A9"
        />
      </v-col>
    </div>

    <div class="row w-100 mx-auto">
      <v-col cols="12" sm="6">
        <v-btn class="w-100" @click="reset" :loading="loading">Reset</v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-btn class="w-100" @click="done" :loading="loading">Done</v-btn>
      </v-col>
    </div>
  </div>
</template>

<script>
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  components: {
    VueCtkDateTimePicker,
  },
  props: {
    group: {
      type: Object,
      default: Object,
    },
    noSubmitMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      loading: false,
      datePickerKey: 0,
    };
  },
  computed: {
    minimumEndDate() {
      // minimum end date greater than start date or greater than current date
      let minimumEndDate,
        now = this.$moment(),
        startDate = this.$moment(this.startDate);

      if (this.group.endDate) {
        // in case of editing, just return the end date
        minimumEndDate = this.$moment(this.group.endDate);
      } else {
        minimumEndDate = startDate > now ? startDate : now;
      }

      return String(this.$moment(minimumEndDate).tz(this.profile.timeZone));
    },
  },
  methods: {
    reset() {
      this.startDate = null;
      this.endDate = null;

      // to ensure it waited until the state updated
      setTimeout(() => this.done(), 0);
    },
    async done() {
      if (this.noSubmitMode) {
        this.$emit("close", {
          startDate: this.startDate,
          endDate: this.endDate,
        });
        return;
      }

      this.loading = true;

      const startDate = this.startDate && new Date(this.startDate).toJSON();
      const endDate = this.endDate && new Date(this.endDate).toJSON();

      let requestData = this.$structuredClone(this.group);
      requestData.startDate = startDate;
      requestData.endDate = endDate;
      requestData.products = requestData.products.map((product) => {
        if (product.item && product.item._id) {
          return product.item._id;
        }
      });

      this.$store
        .dispatch("offersModule/updateGroup", requestData)
        .then(async (res) => {
          this.loading = false;

          await this.$store.dispatch("showSnack", {
            text: "Date Updated Successfully",
            color: "success",
          });

          this.$emit("close");
          await this.$store.dispatch("offersModule/getAllData");
        })
        .catch(async (err) => {
          this.loading = false;
          let errorMessage = err.message || err.error || "Something went wrong";
          errorMessage =
            typeof errorMessage === "object"
              ? errorMessage.details[0].message || "Something went wrong"
              : errorMessage;

          await this.$store.dispatch("showSnack", {
            text: errorMessage,
            color: "error",
          });
        });
    },
    updateDates(group = this.group) {
      const startDate = group.startDate
        ? this.$moment(this.dateToTimezone(group.startDate))
        : this.$moment(this.dateToTimezone()).format();

      this.startDate = startDate;
      this.endDate = group.endDate
        ? this.$moment(this.dateToTimezone(group.endDate))
        : this.$moment(this.dateToTimezone()).format();

      setTimeout(() => {
        this.datePickerKey = this.datePickerKey + 1;
      }, 500);
    },
  },
  mounted() {
    this.updateDates();
  },
  watch: {
    group: {
      handler(val) {
        this.updateDates(val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.date-picker-dialog {
  .date-picker-btn {
    border-radius: 8.281px;
    border: 0px solid #aaa;
    background-color: #f5f5f5;
    color: #000000;
    padding: 10px 25px;
    text-align: center;

    &.active {
      background-color: #0195a9;
      color: #fff;
    }
  }

  .date-pickers {
    // gap: 15px;

    .date-time-picker {
      width: 100%;
    }
  }
}
</style>
