<template>
  <div class="select-theme-container d-flex justify-space-between">
    <div
      class="theme-preview-container flex-grow-1 d-flex justify-center align-center"
    >
      <div
        class="theme-preview d-none d-lg-block"
        :class="[selectedTab === 1 ? 'show' : 'hide']"
        :key="selectedTheme._id"
      >
        <div class="w-100 h-100 position-relative" v-if="item.products.length">
          <div style="width: 100%; height: 100%">
            <theme-layout
              :is-live-changes-preview="true"
              :live-theme-changes="liveThemeChanges"
              :key="themeChangedKey"
              :products="filterProducts(item.products)"
              :currentGroup="item"
              :currency="currencySymbol"
              :active-theme="selectedTheme"
            ></theme-layout>
          </div>

          <div
            v-if="fitTextLoader"
            class="loader-overlay w-100 h-100 d-flex align-center justify-center"
            :style="`background-color: ${liveThemeChanges.container.color}`"
          >
            <v-progress-circular
              color="primary"
              indeterminate
              size="64"
            ></v-progress-circular>
          </div>
        </div>

        <div
          v-else
          class="no-products w-100 h-100 d-flex justify-center align-center"
        >
          <h1>Start adding products to see the changes here!</h1>
        </div>
      </div>
    </div>

    <div class="select-theme-tabs">
      <v-tabs v-model="selectedTab" class="tab-wrapper d-none d-lg-block">
        <v-tab tag="div" class="select-themes-tab flex-grow-1"></v-tab>
        <v-tab tag="div" class="edit-themes-tab flex-grow-1"></v-tab>
      </v-tabs>

      <v-tabs-items class="w-100" v-model="selectedTab">
        <v-tab-item>
          <div class="select-theme flex-between-column">
            <div style="padding: 20px">
              <div class="w-100 d-flex align-center justify-space-between px-3">
                <div
                  class="max-width w-100"
                  :class="isMobile ? 'text-center' : ' text-left'"
                >
                  <span class="black--text font-24 font-500"
                    >Select the Theme for Group</span
                  >
                </div>
                <img
                  width="15px"
                  class="cursor-pointer"
                  @click="$emit('close', item._id)"
                  :src="require('@/assets/images/icons/close.svg')"
                />
              </div>
              <div
                class="primary radius-30 d-inline-block px-3 py-1 mx-3 mt-1 mb-4"
              >
                <span class="font-16 white--text">{{ item.name }}</span>
              </div>

              <!-- themes -->
              <v-row>
                <v-col cols="12" sm="6" v-for="(theme, i) in themes" :key="i">
                  <div class="position-relative">
                    <v-btn
                      class="edit-theme-btn d-none d-lg-inline-flex"
                      @click="editTheme(theme)"
                    >
                      <img
                        src="@/assets/images/icons/pencil.png"
                        alt="Edit icon"
                      />

                      <img
                        v-if="profile.plan.isFree"
                        class="pro-flag ml-1"
                        width="20px"
                        height="20px"
                        :src="require('@/assets/images/icons/pro-flag.svg')"
                      />
                    </v-btn>
                    <div
                      class="select-theme-card d-grid"
                      @click="selectTheme(theme)"
                    >
                      <img
                        class="object-contain image-theme"
                        width="100%"
                        height="170px"
                        :src="theme.img"
                        alt=""
                        :class="theme.selected ? 'border--primary' : ''"
                      />
                      <span class="text-center d-inline-block">{{
                        theme.name
                      }}</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="px-3 text-center mt-4 bottom-sticky">
              <v-btn
                width="100%"
                class="radius-10"
                height="40px"
                color="primary"
                :loading="loading"
                :disabled="loading"
                @click="submitHandler"
              >
                <span class="font-18 font-500 white--text">Done</span>
              </v-btn>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item v-if="!isMobile && !profile.plan.isFree">
          <edit-theme
            :selected-theme="selectedTheme"
            @theme-changed="liveThemeChanges = $event"
            @replay="themeChangedKey = Math.random()"
            @close="selectedTab = 0"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import EditTheme from "./EditTheme.vue";
import ThemeLayout from "@/components/themes/ThemeLayout.vue";

export default {
  components: {
    EditTheme,
    ThemeLayout,
  },
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  data: () => ({
    loading: false,
    selectedTab: 0,
    themes: [],
    themeImages: [
      { _id: "1", img: require("@/assets/images/themes/Group3872slide.svg") },
      { _id: "2", img: require("@/assets/images/themes/Group 514.svg") },
      { _id: "3", img: require("@/assets/images/themes/13.svg") },
      {
        _id: "4",
        img: require("@/assets/images/themes/Full_screen_fade.svg"),
      },
      { _id: "5", img: require("@/assets/images/themes/Group 458.svg") },
      { _id: "6", img: require("@/assets/images/themes/Group 555.svg") },
      { _id: "7", img: require("@/assets/images/themes/Group 566.svg") },
      { _id: "8", img: require("@/assets/images/themes/creative-theme.svg") },
      { _id: "9", img: require("@/assets/images/themes/card-design.svg") },
      {
        _id: "11",
        img: require("@/assets/images/themes/Group3903slidesGroup.svg"),
      },
      {
        _id: "12",
        img: require("@/assets/images/themes/Full_screen_fade.svg"),
      },
      {
        _id: "13",
        img: require("@/assets/images/themes/Group3842row2col.svg"),
      },
      {
        _id: "14",
        img: require("@/assets/images/themes/Group388Carousel.svg"),
      },
      { _id: "15", img: require("@/assets/images/themes/Group 463.svg") },
      { _id: "16", img: require("@/assets/images/themes/Group 480.svg") },
      { _id: "17", img: require("@/assets/images/themes/Group 481.svg") },
      { _id: "18", img: require("@/assets/images/themes/Group3832grid.svg") },
      { _id: "19", img: require("@/assets/images/themes/Group438.svg") },
      {
        _id: "20",
        img: require("@/assets/images/themes/Group388Carousel.svg"),
      },
      { _id: "21", img: require("@/assets/images/themes/flipppp[pp.svg") },
      { _id: "22", img: require("@/assets/images/themes/Group386verical.svg") },
    ],
    selectedTheme: {
      themeBgColor: {
        color: null,
      },
      title: {
        delay: 0,
        textColor: "#000000",
        bgColor: "",
        animation: "Fade In",
      },
      price: {
        delay: 0,
        textColor: "#000000",
        bgColor: "",
        animation: "Fade In",
      },
      oldPrice: {
        animation: "",
        bgColor: "",
        delay: 0,
        textColor: "",
      },
      discount: {
        delay: 0,
        textColor: "#000000",
        bgColor: "",
        animation: "Fade In",
      },
      desc: {
        animation: "",
        bgColor: "",
        delay: 0,
        textColor: "",
      },
      banner: {
        delay: 0,
        textColor: "#000000",
        bgColor: "",
        animation: "Fade In",
      },
    },
    liveThemeChanges: {},
    themeChangedKey: Math.random(),
  }),
  computed: {
    availableThemes() {
      return this.fetchedThemes
        .filter((theme) => !theme.inactive)
        .map((theme) => {
          return {
            ...theme,
            img: this.themes.find((t) => t._id == theme._id)?.img,
          };
        });
    },
  },
  methods: {
    selectTheme(theme) {
      this.themes.forEach((th) => {
        if (th._id == theme._id) {
          th.selected = true;
        } else {
          th.selected = false;
        }
      });

      this.selectedTheme = theme;
    },
    editTheme(theme) {
      if (this.profile.plan.isFree) {
        this.$store.dispatch("setProTipDialog", {
          show: true,
          title: "Customizing Theme",
          description:
            "Match Your Brand's Style with Custom Themes. Create a consistent look across all your digital displays.",
        });
        return;
      }

      this.selectTheme(theme);

      this.$nextTick(() => {
        this.selectedTab = 1;
      });
    },
    async submitHandler() {
      this.loading = true;

      let selectedThemeId = this.themes.filter((theme) => theme.selected)[0];

      this.$store
        .dispatch("offersModule/changeGroupTheme", {
          data: { themeId: selectedThemeId._id },
          id: this.item._id,
        })
        .then((res) => {
          this.loading = false;

          this.$store.dispatch("showSnack", {
            text: "Theme selected successfully",
            color: "success",
          });
          this.$emit("close", this.item._id);
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch("showSnack", {
            text: err.message || err.error || "Something went wrong",
            color: "error",
          });
        });
    },
  },

  mounted() {
    let themes = this.fetchedThemes.map((theme) => {
      return {
        ...theme,
        img: this.themeImages.find((t) => t._id == theme.numericId)?.img,
      };
    });

    themes = themes.sort((a, b) => a.numericId - b.numericId);

    // add selected property to themes
    themes.forEach((theme) => {
      if (this.item.theme.numericId == theme.numericId) {
        theme.selected = true;
      } else {
        theme.selected = false;
      }
    });

    this.themes = themes;
    this.selectedTheme = themes.find((t) => t.selected);
    this.liveThemeChanges = themes.find((t) => t.selected);
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.themes.forEach((theme) => {
          if (this.item.theme._id == theme._id) {
            theme.selected = true;
          } else {
            theme.selected = false;
          }
        });

        this.selectedTheme = this.themes.find((t) => t.selected);
        this.liveThemeChanges = this.themes.find((t) => t.selected);
      },
    },
    liveThemeChanges: {
      deep: true,
      handler() {
        this.themeChangedKey = Math.random();
      },
    },
  },
};
</script>

<style lang="scss">
.select-theme-container {
  // position: fixed;
  // top: 0px;
  // right: 0px;
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 99;

  .theme-preview-container {
    width: calc(100% - 650px);
    z-index: 1;

    @media (max-width: $breakpoint-md) {
      display: none !important;
    }

    .theme-preview {
      width: 95%;
      height: 60%;
      background-color: white;
      transition: 0.3s;
      overflow: hidden !important;
      opacity: 0;

      &.show {
        opacity: 1;
      }
    }
  }

  .select-theme-tabs {
    width: 550px;
    height: 100vh;
    overflow-y: scroll;
    background: white;
    box-shadow: -2px 0px 10px 0px #00000027;
    transition: all ease 0.3s;
    z-index: 2;

    @media (max-width: $breakpoint-md) {
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #0195a9;
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(#0195a9, 0.9);
    }
  }

  .v-tabs-bar {
    height: 13px;

    .v-tab {
      height: 13px;
      padding: 0px;
      background-color: #d9d9d9;
      max-width: unset;
    }

    .v-tabs-slider-wrapper {
      height: 13px !important;
    }
  }

  .edit-theme-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    min-width: unset !important;
    width: fit-content;
    height: 28px !important;
    padding: 0 8px !important;
    background: white;
    border-radius: 10px;
    box-shadow: -1.0592px 1.0592px 2.11841px 0px rgba(0, 0, 0, 0.25);
    z-index: 2;
  }

  .select-theme-card {
    position: relative;

    &__buttons {
      background: #e6f4f1;
      padding: 10px 20px;
      transition: 0.3s;
      position: absolute;
      bottom: 35px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
      font-size: 16px;
      padding: 4px 30px;
      color: white;
    }
  }
}

.image-theme {
  border: 3px solid transparent;
  border-radius: 20px !important;

  &:hover {
    border: 3px solid #0195a9 !important;
  }

  &.border--primary {
    border: 3px solid #0195a9 !important;
    border-radius: 20px !important;
  }
}

.bottom-sticky {
  position: sticky;
  bottom: 0;
  background-color: #f4f4f4;
  width: 100%;
  padding: 20px 10px;
  z-index: 10;
}

.theme-property {
  border-radius: 7px;
  background: #f5f5f5;

  .v-select {
    width: 100px;

    .v-text-field__details {
      display: none;
    }
  }

  .properties {
    & > span,
    input,
    .color-picker-btn {
      background: #ffffff;
      border-radius: 5px;
      // text-align: center;
      padding: 8px;
    }

    input {
      width: 60px;
    }

    .color-picker-btn {
      width: fit-content;
    }

    .multiselect__content {
      padding-left: 0px;
    }

    .multiselect__placeholder {
      max-width: 80px;
      height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.select-theme {
  height: calc(100vh - 13px);
}
</style>
