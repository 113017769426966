<template>
  <div class="filter">
    <v-select
      placeholder="All Items"
      :items="filters"
      item-text="title"
      dense
      :hide-details="true"
      height="50px"
      @change="handleChange"
    >
      <template v-slot:append>
        <img
          :src="require('@/assets/images/icons/arrow-down-white.svg')"
          alt=""
        />
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  data: () => ({
    filters: [
      { title: "All Items", count: 6 },
      { title: "Active Items", count: 5 },
      { title: "Inactive", count: 1 },
      { title: "Deleted Items", count: 2 },
    ],
  }),
  methods:{
    handleChange(event){
        this.$emit('change', {title: event})
    }
  }
};
</script>

<style lang="scss">
.filter {
  .v-input {
    width: 140px;
    margin-top: 0px;
  }
  .v-input__slot {
    &:before,
    &:after {
      display: none !important;
    }
  }
  .v-select__selection{
    color: white
  }
}
</style>
