<template>
  <div class="pa-4 ccs">
    <div>
      <img
        width="20px"
        class="cursor-pointer"
        :src="require('@/assets/images/icons/arrow-left-black.svg')"
        @click="$emit('close')"
      />
      <div
        class="d-flex flex-wrap justify-center flex-column align-center pxs-3 pb-1 pt-0"
      >
        <div
          class="picker d-fdlex justify-center align-center pa-0 flex-column"
        >
          <v-color-picker
            v-model="picker"
            elevation="15"
            mode="hexa"
            class="compoPicker"
          ></v-color-picker>
        </div>
        <div class="submitBtn my-3 pa-0 px-3">
          <v-btn
            block
            height="40px"
            color="primary"
            elevation="0"
            :loading="loading"
            @click="submitHandler"
          >
            <span class="font-500 white--text">Done</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showColorPickerFromPanelTab: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    currentGroup: {
      type: Object,
      default: null,
    },
    noSubmitMode: {
      type: Boolean,
      default: false,
    },
    preSelectedColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      picker: "",
      loading: false,
    };
  },
  computed: {},
  methods: {
    // submitHandler() {
    //   this.$emit("selectedGroupColor", this.picker);
    // },
    async submitHandler() {
      if (this.noSubmitMode) {
        this.$emit("close", this.picker);
        return;
      }

      let requestData = this.$structuredClone(this.currentGroup);
      if (this.picker) {
        this.loading = true;
        requestData.bg_color = this.picker;
        requestData.products = requestData.products.map((product) => {
          if (product.item && product.item._id) {
            return product.item._id;
          }
        });

        this.$store
          .dispatch("offersModule/updateGroup", requestData)
          .then(async (res) => {
            await this.$store.dispatch("showSnack", {
              text: "Color Added Successfully",
              color: "success",
            });
            this.loading = false;
            this.$emit("close");
          })
          .catch(async (err) => {
            await this.$store.dispatch("showSnack", {
              text: err.message || err.error || "Something went wrong",
              color: "error",
            });
            this.loading = false;
            this.$emit("close");
          });
      }
    },
  },

  created() {
    if (this.isEdit || this.currentGroup) {
      this.currentGroup.bg_color
        ? (this.picker = this.currentGroup.bg_color)
        : (this.picker = "#FF000000");
    }

    if (this.preSelectedColor) {
      this.picker = this.preSelectedColor;
    }
  },
};
</script>

<style lang="scss" scoped>
.upload-logo {
  height: 100px;
  width: 100px !important;
}
.picker {
  width: 300px;
}
.compoPicker {
  box-shadow: none !important;
}

.submitBtn {
  width: 300px;
}
.submitBtn button {
  font-size: 1.2rem;
  border-radius: 13px;
}
.inPanel {
  position: absolute;
  top: 0px;
  left: -261px;
  z-index: 1000;
  background-color: white;
}
.none {
  display: none;
}
.colorFather {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
</style>
