<template>
  <div class="flex-grow-1 w-100">
    <v-expansion-panel class="single-tab mb-3" @click="onPanelChange">
      <v-expansion-panel-header class="justify-between">
        <template v-slot:actions>
          <v-icon
            :class="`arrow-${index}`"
            :ref="`arrow-${index}`"
            color="error"
          >
            mdi-alert-circle
          </v-icon>
        </template>
        <span class="font-18 black--text">
          {{ group.name }} ({{ group.products.length }})
        </span>
        <div
          class="action-div d-flex align-center justify-end"
          v-if="!atDeletedSide"
        >
          <v-tooltip top color="white">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-none d-md-flex assign-to-screen align-center px-3 py-2 radius-15 cursor-pointer"
                @click.stop="$emit('openSelectScreen', group)"
                v-on="on"
                v-bind="attrs"
              >
                <img
                  width="40px"
                  src="@/assets/images/icons/assign-to-screen.svg"
                  alt=""
                />
              </div>
            </template>
            <span class="black--text font-16 d-inline-block mr-2">
              Assign to screen
            </span>
          </v-tooltip>

          <!-- date -->
          <v-tooltip
            v-if="group.startDate && group.endDate"
            top
            content-class="date-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="group-date d-none d-md-flex flex-wrap justify-center align-center mr-3 cursor-pointer"
                @click.stop="openDatePicker"
                v-on="on"
                v-bind="attrs"
              >
                <img
                  class="mr-2"
                  :class="checkDate(group.endDate)"
                  :src="require('@/assets/images/icons/date.svg')"
                />
                <span class="font-14 black--text font-500">
                  {{
                    $moment(group.startDate)
                      .tz(profile.timeZone)
                      .format("DD MMM")
                  }}
                  &gt;
                  <span v-if="group.endDate">
                    {{
                      $moment(group.endDate)
                        .tz(profile.timeZone)
                        .format("DD MMM")
                    }}
                  </span>
                </span>
              </div>
            </template>
            <span class="text-black">
              <span>
                {{
                  $moment(group.startDate).tz(profile.timeZone).format("DD MMM")
                }}

                <span class="time ml-2">
                  <img
                    :src="require('@/assets/images/icons/clock.svg')"
                    alt="arrow right"
                  />
                  {{
                    $moment(group.startDate)
                      .tz(profile.timeZone)
                      .format("HH:mm")
                  }}
                </span>
              </span>
              <img
                v-if="group.endDate"
                :src="require('@/assets/images/icons/arrow-right-cyan.svg')"
                alt="arrow right"
                class="mx-2"
              />
              <span v-if="group.endDate">
                {{
                  $moment(group.endDate).tz(profile.timeZone).format("DD MMM")
                }}
                <span class="time ml-2">
                  <img
                    :src="require('@/assets/images/icons/clock.svg')"
                    alt="arrow right"
                  />
                  {{
                    $moment(group.endDate).tz(profile.timeZone).format("HH:mm")
                  }}
                </span>
              </span>
            </span>
          </v-tooltip>

          <v-tooltip v-else top color="white">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="group-date d-none d-md-flex align-center mr-3 cursor-pointer"
                @click.stop="openDatePicker"
              >
                <img
                  class="mr-2"
                  :class="checkDate(group.endDate)"
                  :src="require('@/assets/images/icons/date.svg')"
                />
              </div>
            </template>
            <span class="text-black">Schedule</span>
          </v-tooltip>

          <!-- themes -->
          <v-tooltip top color="white">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="d-none d-sm-flex align-center px-3 py-2 radius-15 white mr-3 cursor-pointer"
                @click.stop="$emit('openThemeSelector', group)"
              >
                <span class="black--text font-16 d-inline-block mr-2">
                  {{ themeTitle }}
                </span>
                <img :src="require('@/assets/images/icons/dots.svg')" alt="" />
              </div>
            </template>
            <span class="text-black">Select theme</span>
          </v-tooltip>
          <!-- Active Group -->
          <div
            class="offer-item__desc__active text-center pa-0"
            v-if="!atDeletedSide"
            @click.stop
          >
            <p class="d-block font-18 w-100 ma-0 mr-10 text-center" @click.stop>
              Active
            </p>
            <v-switch
              @click.stop
              class="mt-0 mr-0"
              :hide-details="true"
              v-model="group.active"
              inset
              @click="switchHandler($event, group)"
            ></v-switch>
          </div>
          <!--color picker -->
          <!-- <div class="d-flex align-center relative" @click.stop>
            <img
              @click="$emit('openColorPicker', group)"
              width="25px"
              class="mx-2 mr-4 cursor-pointer"
              :src="require('@/assets/images/icons/Group 418.svg')"
            />
          </div> -->
          <!-- add new -->
          <v-tooltip top color="white">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="d-none d-sm-flex align-center"
              >
                <img
                  @click.stop="addNewHandler(group, index)"
                  width="20px"
                  class="mx-2 mr-4 cursor-pointer"
                  :src="require('@/assets/images/icons/add.svg')"
                />
              </div>
            </template>
            <span class="text-black">Add New Item</span>
          </v-tooltip>
          <!-- delete group -->
          <div class="d-none d-sm-flex align-center">
            <v-tooltip top color="white">
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-on="on"
                  v-bind="attrs"
                  @click.stop="deletePermanentHandler(group)"
                  width="20px"
                  class="mx-2 mr-4 cursor-pointer dl-perm"
                  :src="require('@/assets/images/icons/delete-black.svg')"
                />
              </template>
              <span class="text-black">Delete Group</span>
            </v-tooltip>
            <v-tooltip top color="white">
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-on="on"
                  v-bind="attrs"
                  @click.stop="editHandler(group)"
                  width="20px"
                  class="mx-2 mr-4 cursor-pointer"
                  :src="require('@/assets/images/icons/setting-black.svg')"
                />
              </template>
              <span class="text-black">Edit Group</span>
            </v-tooltip>
          </div>
        </div>

        <div v-else class="d-flex align-center justify-end">
          <!-- show items -->
          <div class="d-flex align-center">
            <img
              class="cursor-pointer group-arrow"
              :src="require('@/assets/images/icons/restore.svg')"
              @click.stop="restoreHandler(group)"
              v-if="group.deleted"
            />
            <img
              @click.stop="deletePermanentHandler(group)"
              v-if="group.deleted"
              width="20px"
              class="mx-2 mr-4 cursor-pointer"
              :src="require('@/assets/images/icons/delete-red.svg')"
            />
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div>
          <div class="mb-5">
            <NewModal
              class="mt-2"
              v-if="showForm"
              :type="type"
              :chosen-group="group._id"
              :is-edit="false"
              @saveProduct="createProduct"
              @closeModal="closeModal"
            />

            <!-- <hr class="primary" /> -->
          </div>

          <v-row v-if="!showForm" :key="itemSelectedKey">
            <template
              v-if="
                group.products.length > 0 &&
                typeof group.products[0].item == 'object'
              "
            >
              <draggable
                @change="orderChangeHandler(group, index)"
                v-model="group.products"
                style="display: contents"
                class="row mt-4 justify-start"
                handle=".handle"
                :move="checkMove"
                ghost-class="ghost"
              >
                <v-col
                  cols="12"
                  class="pa-0 px-1 ma-0"
                  v-for="(item, i) in group.products"
                  :key="item.item ? item.item._id : Math.random()"
                >
                  <offer-item
                    :item="{ ...item.item, i }"
                    :isGroup="true"
                    :type="type"
                    :atDeletedSide="atDeletedSide"
                    :chosen-group="group._id"
                    @getData="$emit('updateData')"
                    @dragItem="$emit('dragItem', item.item, group, 'group')"
                    @selectItem="$emit('selectItem', item.item)"
                    @edit="editHandlerItem"
                    @saveProduct="updateProduct"
                  ></offer-item>
                </v-col>
              </draggable>
            </template>
            <v-col cols="12" class="pa-0 px-1 ma-0 d-flex justify-center">
              <button
                class="add-item-btn d-flex justify-center align-center mt-2 mb-2"
                @click.prevent="addNewHandler(group, index)"
              >
                <img
                  :src="require('@/assets/images/icons/plus-white.svg')"
                  alt="Add"
                />
              </button>
            </v-col>
          </v-row>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-dialog
      v-model="newProductDialog"
      max-width="530px"
      content-class="white "
      :key="newProductDialog"
    >
      <new-product
        @close="newProductDialog = false"
        :isEdit="isEditProduct"
        :key="keyProductDialog"
        :selectedItem="selectedItem"
        :chosenGroup="chosenGroup"
      ></new-product>
      <!-- @update="[$emit('updateData'), (newProductDialog = false)]" -->
    </v-dialog>

    <!-- add/edit new -->
    <v-dialog v-model="categoryDialog" max-width="450px">
      <new-category
        @close="closeCategoryDialog(group._id)"
        :isEdit="true"
        :item="selectedItem"
        :key="categoryDialog"
      ></new-category>
    </v-dialog>

    <!-- Delete Modal -->
    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <custom-delete
        @close="deleteDialog = false"
        :urlApi="urlApi"
        :itemID="selectedItem._id"
        @getData="deleteGroup"
        :delete-type="deleteGroupType"
      ></custom-delete>
    </v-dialog>

    <v-dialog
      v-if="datePickerDialog"
      v-model="datePickerDialog"
      max-width="400"
      content-class="date-picker-dialog-container"
    >
      <date-picker-dialog
        :group="group"
        @close="datePickerDialog = false"
        :key="datePickerDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import NewProduct from "@/components/dialogs/NewProduct.vue";
import NewCategory from "./NewCategory.vue";
import ColorPicker from "@/components/dialogs/ColorPicker.vue";
import OfferItem from "./OfferItem.vue";
import NewModal from "./NewModal.vue";
import DatePickerDialog from "./DatePickerDialog.vue";
import moment from "moment";

export default {
  components: {
    OfferItem,
    NewProduct,
    NewModal,
    NewCategory,
    DatePickerDialog,
  },
  props: {
    atDeletedSide: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "item",
    },
    group: {
      type: Object,
      default: null,
    },
    isTrash: {
      type: Boolean,
      default: false,
    },
    index: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      testColor: false,
      // group: this.groupData,
      showColorPicker: false,
      bannerInput: false,
      bannerText: null,
      bannerArray: [],
      uploadImage: true,
      currSection: 0,
      isPanelOpen: false,
      showForm: false,
      itemSelectedKey: false,
      selectedItem: {},
      selectThemeDialog: false,
      categoryDialog: false,
      chosenGroup: {},
      newProductDialog: false,
      isEditProduct: false,
      deleteDialog: false,
      urlApi: false,
      keyProductDialog: false,
      product: { images: [{ image: {} }] },
      images: [],
      imageFormUpload: {
        imgDataUrl: "",
        url: null,
      },
      fileKey: false,
      deleteGroupType: "archive",
      datePickerDialog: false,
      showTooltip: true,
    };
  },
  computed: {
    themes() {
      return this.$store.getters["fetchedThemes"];
    },
    themeTitle() {
      // //console.log("this.group.theme",this.group , this.themes);
      const theme =
        this.fetchedThemes.find(
          (theme) =>
            theme._id == this.group.theme._id ||
            theme.numericId == this.group.theme.numericId
        ) || this.fetchedThemes[0];

      return theme?.name;
    },
    role() {
      return localStorage.getItem("role");
    },
    groupItems() {
      // return this.$store.state.offersModule.allData;
      return this.$store.getters["offersModule/allData"];
    },
    // ...mapState("offersModule", ["allData"]),
    // ...mapGetters("offersModule", ["allData"]),
  },
  watch: {
    // group: {
    //   immediate: true,
    //   deep: true,
    //   handler(newValue, oldValue) {
    //     //console.log("newValue", newValue.name, "=>", newValue.theme);
    //     //console.log("oldValue", oldValue?.name, "=>", oldValue?.theme);
    //     // //console.log("oldValue", oldValue);
    //   },
    // },
    // groupItems: {
    //   immediate: false,
    //   deep: true,
    //   handler(newValue) {
    //     this.group = newValue;
    //   },
    // },
  },
  methods: {
    moment,
    // eslint-disable-next-line no-unused-vars
    closeCategoryDialog(id) {
      this.categoryDialog = false;
      const find = this.groupItems.find((x) => x._id == id);
      this.group.name = find.name;
      this.group.theme = find.theme;
    },
    // eslint-disable-next-line no-unused-vars
    closeThemeModal(id) {
      this.selectThemeDialog = false;
      const find = this.groupItems.find((x) => x._id == id);
      this.group.name = find.name;
      this.group.theme = find.theme;
    },
    async createProduct(data) {
      //console.log("thi close dialog");
      data.group = this.chosenGroup._id;
      let formData = new FormData();

      for (let key in data) {
        if (data[key]) {
          if (
            typeof data[key] != "object" ||
            key == "images" ||
            key == "video"
          ) {
            formData.append(key, data[key]);
          } else {
            formData.append(key, JSON.stringify(data[key]));
          }
        }
      }

      try {
        const res = await this.$store.dispatch("offersModule/saveProduct", {
          groupId: this.chosenGroup._id,
          formData,
        });

        if (res.success) {
          this.$store.dispatch("showSnack", {
            text: `${this.isEdit ? "Edited" : "Added "} Successfully`,
            color: "success",
          });
          this.showForm = false;
        }
      } catch (e) {
        this.showForm = false;
        this.$store.dispatch("showSnack", {
          text: e.message || e.error || "Something went wrong!",
          color: "error",
        });
      }
    },

    async updateProduct({ data, id }) {
      let formData = new FormData();
      for (let key in data) {
        if (
          (key == "images" && typeof data[key] != "object") ||
          (key == "video" && typeof data[key] != "object")
        ) {
          continue;
        } else if (typeof data[key] != "object") {
          formData.append(key, data[key]);
        } else {
          formData.append(key, JSON.stringify(data[key]));
        }
      }

      this.$store
        .dispatch("offersModule/updateProduct", {
          formData,
          productId: id,
        })
        .then((res) => {
          this.$store.dispatch("showSnack", {
            text: `${this.isEdit ? "Edited" : "Added "} Successfully`,
            color: "success",
          });
        })
        .catch((err) => {
          this.$store.dispatch("showSnack", {
            text: err.message || err.error || "Something went wrong",
            color: "error",
          });
        });
    },

    addBanner() {},
    updateHandler() {
      this.selectThemeDialog = false;
    },
    closeModal() {
      //console.log("close create modal");
      this.showForm = false;
    },
    uploadImageHandler(imageUrl, imageData) {
      this.imageFormUpload.imgDataUrl = imageUrl;
      this.imageFormUpload.url = imageData;
    },
    saveData() {
      this.showForm = false;
    },
    onPanelChange() {
      this.isPanelOpen = !this.isPanelOpen;
      if (this.showForm) {
        //console.log("toggle 2");

        this.showForm = false;
      }
    },
    checkDate(date) {
      if (date) {
        let itemDate = new Date(date).toISOString();
        let now = new Date().toISOString();
        if (itemDate >= now) {
          return "";
        } else {
          return " filter-grey";
        }
      } else {
        return " filter-grey";
      }
    },
    async cropSuccess(imgDataUrl) {
      if (this.images.length < 6) {
        this.images[this.selectedIndex].imgDataUrl = imgDataUrl;
        this.uploadImage = false;
        this.images[this.selectedIndex].image.file = this.dataURLtoFile(
          imgDataUrl,
          "image.png"
        );

        if (this.images.length < 5) {
          this.images.push({ image: {} });
        }
      }
    },
    editHandlerItem(item) {
      this.selectedItem = {};
      this.isEditProduct = true;
      this.newProductDialog = true;
      this.keyProductDialog = !this.keyProductDialog;
      this.selectedItem = { ...item };
    },
    openDatePicker() {
      this.datePickerDialog = true;
    },
    selectThemeHandler(item) {
      this.selectThemeDialog = true;
      this.selectedItem = item;
    },

    async addNewHandler(group, i) {
      console.log("add new handler");
      setTimeout(() => {
        this.showForm = true;
      }, 1);
      if (this.isPanelOpen && !this.showForm) {
        this.showForm = true;
      } else {
        // //console.log("toggle 2");
        this.$refs[`arrow-${i}`].$el.click();
      }
      this.chosenGroup = group;

      // this.newProductDialog = true;
      this.isEditProduct = false;
      this.selectedItem = {};
    },
    async deleteHandler(item) {
      this.deleteDialog = true;
      this.urlApi = process.env.VUE_APP_GROUPS;
      this.selectedItem = item;
      this.deleteGroupType = "archive";
    },
    editHandler(item) {
      this.categoryDialog = true;
      //console.log(item, "itemmm");
      this.selectedItem = item;
    },
    async restoreHandler(item) {
      try {
        await this.$store.dispatch("offersModule/restoreGroup", item);
      } catch {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },

    updateData() {
      this.newProductDialog = false;
    },
    deletePermanentHandler(item) {
      this.deleteDialog = true;
      this.urlApi = process.env.VUE_APP_GROUPS;
      this.selectedItem = item;
      this.deleteGroupType = "delete";
    },
    deleteFile(event, id) {
      this.images[event] = {};
      this.deleteImageHandler(id);
      this.fileKey = !this.fileKey;
    },
    deleteImageOnClient() {
      this.imageFormUpload.imgDataUrl = "";
      this.imageFormUpload.url = "";
    },
    checkMove: function (e) {
      // return false if not the context
      return e.relatedContext.element._id == e.relatedContext.element._id;
    },
    async deleteGroup(data) {
      try {
        //console.log(data, "id :>> ");
        await this.$store.dispatch("offersModule/deleteGroup", data);
        // await this.$store.dispatch("offersModule/getAllTrash", "deleted");
      } catch {
        await this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
    async orderChangeHandler(group, idx) {
      let newProducts = this.groupItems[idx].products.map(
        (item) => item.item._id
      );
      let sortObj = {
        groupId: group._id,
        products: newProducts,
      };
      await this.$store.dispatch(`offersModule/sortProducts`, sortObj);
    },
    colorPicker(group, i) {
      this.showColorPicker = !this.showColorPicker;
      //console.log(group, "group");

      this.selectedItem = group;
    },
    async selectedGroupColorHandler(selectedColor) {
      // const find = this.groupItems.find((x) => x._id == this.group.id);

      let requestData = this.$structuredClone(this.group);

      if (selectedColor) {
        requestData.bg_color = selectedColor;
        requestData.products = requestData.products.map((product) => {
          if (product.item && product.item._id) {
            return product.item._id;
          }
        });
        let res = await this.$store.dispatch(
          "offersModule/updateGroup",
          requestData
        );

        if (res.success) {
          await this.$store.dispatch("showSnack", {
            text: "Color Added Successfully",
            color: "success",
          });
          this.showColorPicker = false;
        } else {
          this.loading = false;
          await this.$store.dispatch("showSnack", {
            text: res.message,
            color: "error",
          });
        }
      }
    },
    async switchHandler(_, group) {
      let requestData = this.$structuredClone(group);

      requestData.products = requestData.products.map((product) => {
        if (product.item && product.item._id) {
          return product.item._id;
        }
      });

      await this.$axios.put(
        `${process.env.VUE_APP_GROUPS}/${group._id}/status`,
        {
          status: group.active ? "active" : "inactive",
        }
      );

      this.$store.dispatch("offersModule/getAllData");

      // let res = await this.$store.dispatch(
      //   "offersModule/updateGroup",
      //   requestData
      // );
    },
  },
  async created() {
    this.product = { images: [{ image: {} }] };
    this.images = [{ image: {}, imgDataUrl: "" }];
    if (this.isEdit) {
      this.product = this.selectedItem;
      this.imageFormUpload.url = this.product.images[0].url;
      this.images = this.product.images.filter((img) => img._id);
      if (this.images.length <= 4) {
        this.images.push({ image: {} });
      }
      this.bannerValueText = this.product.banner;
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/style/create-modal.scss";

.date-picker-dialog-container {
  box-shadow: none;
  max-width: fit-content !important;
}

.group-date,
.assign-to-screen {
  padding: 5px 10px;
  border-radius: 6px;
  transition: 0.3s;

  &:hover {
    background: #dfdfdf;
  }
}

.date-tooltip {
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  color: black;
  border-radius: 9px;
  font-weight: 700;
  padding: 10px 20px;
}

.v-tooltip__content {
  opacity: 1 !important;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.25);

  span.text-black {
    color: black;
    font-weight: 600;
  }
}

.add-item-btn {
  width: 68px;
  height: 46px;
  background-color: $primary;
  border-radius: 10px;
}
</style>
