<template>
  <div
    class="theme-preview-container w-100 h-100 flex-grow-1 d-flex justify-center align-center"
  >
    <div class="theme-preview w-100 h-100 d-none d-lg-block">
      <div class="w-100 h-100">
        <div style="width: 100%; height: 100%">
          <theme-layout
            :products="filterProducts(group.products)"
            :currentGroup="group"
            :active-theme="group.theme"
            :currency="currencySymbol"
          ></theme-layout>
        </div>
      </div>
    </div>

    <div
      v-if="fitTextLoader"
      class="loader-overlay w-100 h-100 d-flex align-center justify-center"
      :style="`background-color: ${group.theme.container.color}`"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import ThemeLayout from "@/components/themes/ThemeLayout.vue";

export default {
  components: {
    ThemeLayout,
  },
  props: {
    group: {
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    currencySymbol() {
      return this.profile?.currency?.symbol ?? "$";
    },
  },
};
</script>

<style>
.loader-overlay {
  height: 100% !important;
}
</style>