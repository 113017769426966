<template>
  <div class="select-theme flex-between-column">
    <div style="padding: 20px" v-if="Object.keys(theme).length">
      <div class="w-100 d-flex align-center justify-space-between px-3">
        <div
          class="max-width w-100"
          :class="isMobile ? 'text-center' : ' text-left'"
        >
          <span class="black--text font-24 font-500">Customizing Theme</span>
        </div>
        <img
          width="20px"
          class="cursor-pointer"
          @click="$emit('close')"
          :src="require('@/assets/images/icons/arrow-left-black.svg')"
        />
      </div>
      <div class="primary radius-30 d-inline-block px-3 py-1 mx-3 mt-1 mb-4">
        <span class="font-16 white--text">{{ theme.name }}</span>
      </div>

      <!-- themes -->
      <v-row>
        <v-col cols="12" v-if="theme.numericId !== 11">
          <div
            class="theme-property pa-3 d-flex flex-wrap justify-space-between align-center"
          >
            <span>Theme color</span>

            <div class="properties d-flex align-center gap-8">
              <v-btn
                class="color-picker-btn radius-15 dark cursor-pointer justify-between"
                title="Text color"
                color="#E1E1E1"
                elevation="0"
                @click.stop="
                  openColorPicker(theme.container.color, 'container')
                "
              >
                <div
                  class="d-flex align-center"
                  :style="`color: ${theme.container.color}`"
                >
                  <span>{{ theme.container.color || "Background color" }}</span>
                </div>
              </v-btn>

              <!-- <multiselect
                v-model="theme.content.title.animation"
                :options="animationOptions"
                selectLabel=""
                deselectLabel=""
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option
                }}</template>

                <template #placeholder>
                  <span
                    class="multiselect__placeholder"
                    title="Select Animation"
                    >Select Animation</span
                  >
                </template>
              </multiselect>

              <input
                v-model="theme.content.title.delay"
                type="number"
                min="0"
                max="10"
                step="0.5"
                value="0"
              /> -->
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          v-if="theme.numericId !== 14 && theme.numericId !== 15"
        >
          <div
            class="theme-property pa-3 d-flex flex-wrap justify-space-between align-center"
          >
            <span>Title and description container</span>

            <div class="properties d-flex align-center gap-8">
              <v-btn
                class="color-picker-btn radius-15 dark cursor-pointer justify-between"
                title="Background color"
                color="#E1E1E1"
                elevation="0"
                @click.stop="
                  openColorPicker(
                    theme.content.container.color,
                    'content.container'
                  )
                "
              >
                <div
                  class="d-flex align-center"
                  :style="`color: ${theme.content.container.color}`"
                >
                  <span>{{
                    theme.content.container.color || "Background color"
                  }}</span>
                </div>
              </v-btn>

              <!-- <multiselect
                v-model="theme.content.container.animation"
                :options="animationOptions"
                selectLabel=""
                deselectLabel=""
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option
                }}</template>

                <template #placeholder>
                  <span
                    class="multiselect__placeholder"
                    title="Select Animation"
                    >Select Animation</span
                  >
                </template>
              </multiselect> -->

              <!-- <input
                v-model="theme.content.container.delay"
                type="number"
                min="0"
                max="10"
                step="0.5"
                value="0"
              /> -->
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div
            class="theme-property pa-3 d-flex flex-wrap justify-space-between align-center"
          >
            <span>Price Container</span>

            <div class="properties d-flex align-center gap-8">
              <v-btn
                class="color-picker-btn radius-15 dark cursor-pointer justify-between"
                title="Text color"
                color="#E1E1E1"
                elevation="0"
                @click.stop="
                  openColorPicker(
                    theme.prices.container.color,
                    'prices.container',
                    true
                  )
                "
              >
                <div
                  class="d-flex align-center"
                  :style="`color: ${theme.prices.container.color}`"
                >
                  <span>{{
                    theme.prices.container.color || "Text color"
                  }}</span>
                </div>
              </v-btn>

              <!-- <multiselect
                v-model="theme.prices.container.animation"
                :options="animationOptions"
                selectLabel=""
                deselectLabel=""
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option
                }}</template>

                <template #placeholder>
                  <span
                    class="multiselect__placeholder"
                    title="Select Animation"
                    >Select Animation</span
                  >
                </template>
              </multiselect>

              <input
                v-model="theme.prices.container.delay"
                type="number"
                min="0"
                max="10"
                step="0.5"
                value="0"
              /> -->
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div
            class="theme-property pa-3 d-flex flex-wrap justify-space-between align-center"
          >
            <span>Banner Container</span>

            <div class="properties d-flex align-center gap-8">
              <v-btn
                class="color-picker-btn radius-15 dark cursor-pointer justify-between"
                title="Text color"
                color="#E1E1E1"
                elevation="0"
                @click.stop="
                  openColorPicker(
                    theme.banner.container.color,
                    'banner.container'
                  )
                "
              >
                <div
                  class="d-flex align-center"
                  :style="`color: ${theme.banner.container.color}`"
                >
                  <span>{{
                    theme.banner.container.color || "Text color"
                  }}</span>
                </div>
              </v-btn>

              <!-- <multiselect
                v-model="theme.banner.container.animation"
                :options="animationOptions"
                selectLabel=""
                deselectLabel=""
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option
                }}</template>

                <template #placeholder>
                  <span
                    class="multiselect__placeholder"
                    title="Select Animation"
                    >Select Animation</span
                  >
                </template>
              </multiselect>

              <input
                v-model="theme.banner.container.delay"
                type="number"
                min="0"
                max="10"
                step="0.5"
                value="0"
              /> -->
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div
            class="theme-property pa-3 d-flex flex-wrap justify-space-between align-center"
          >
            <span>Title text</span>

            <div class="properties d-flex align-center gap-8">
              <v-btn
                class="color-picker-btn radius-15 dark cursor-pointer justify-between"
                title="Text color"
                color="#E1E1E1"
                elevation="0"
                @click.stop="
                  openColorPicker(
                    theme.content.title.color,
                    'content.title',
                    true
                  )
                "
              >
                <div
                  class="d-flex align-center"
                  :style="`color: ${theme.content.title.color}`"
                >
                  <span>{{ theme.content.title.color || "Text color" }}</span>
                </div>
              </v-btn>

              <multiselect
                v-model="theme.content.title.animation"
                :options="animationOptions"
                selectLabel=""
                deselectLabel=""
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option
                }}</template>

                <template #placeholder>
                  <span
                    class="multiselect__placeholder"
                    title="Select Animation"
                    >Select Animation</span
                  >
                </template>
              </multiselect>

              <input
                v-model="theme.content.title.delay"
                type="number"
                min="0"
                max="10"
                step="0.5"
                value="0"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div
            class="theme-property pa-3 d-flex flex-wrap justify-space-between align-center"
          >
            <span>Description text</span>

            <div class="properties d-flex align-center gap-8">
              <v-btn
                class="color-picker-btn radius-15 dark cursor-pointer justify-between"
                title="Text color"
                color="#E1E1E1"
                elevation="0"
                @click.stop="
                  openColorPicker(
                    theme.content.description.color,
                    'content.description'
                  )
                "
              >
                <div
                  class="d-flex align-center"
                  :style="`color: ${theme.content.description.color}`"
                >
                  <span>{{
                    theme.content.description.color || "Text color"
                  }}</span>
                </div>
              </v-btn>

              <multiselect
                v-model="theme.content.description.animation"
                :options="animationOptions"
                selectLabel=""
                deselectLabel=""
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option
                }}</template>

                <template #placeholder>
                  <span
                    class="multiselect__placeholder"
                    title="Select Animation"
                    >Select Animation</span
                  >
                </template>
              </multiselect>

              <input
                v-model="theme.content.description.delay"
                type="number"
                min="0"
                max="10"
                step="0.5"
                value="0"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div
            class="theme-property pa-3 d-flex flex-wrap justify-space-between align-center"
          >
            <span>Banner Text</span>

            <div class="properties d-flex align-center gap-8">
              <v-btn
                class="color-picker-btn radius-15 dark cursor-pointer justify-between"
                title="Text color"
                color="#E1E1E1"
                elevation="0"
                @click.stop="
                  openColorPicker(theme.banner.text.color, 'banner.text', true)
                "
              >
                <div
                  class="d-flex align-center"
                  :style="`color: ${theme.banner.text.color}`"
                >
                  <span>{{ theme.banner.text.color || "Text color" }}</span>
                </div>
              </v-btn>

              <multiselect
                v-model="theme.banner.text.animation"
                :options="animationOptions"
                selectLabel=""
                deselectLabel=""
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option
                }}</template>

                <template #placeholder>
                  <span
                    class="multiselect__placeholder"
                    title="Select Animation"
                    >Select Animation</span
                  >
                </template>
              </multiselect>

              <input
                v-model="theme.banner.text.delay"
                type="number"
                min="0"
                max="10"
                step="0.5"
                value="0"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div
            class="theme-property pa-3 d-flex flex-wrap justify-space-between align-center"
          >
            <span>Price text</span>

            <div class="properties d-flex align-center gap-8">
              <v-btn
                class="color-picker-btn radius-15 dark cursor-pointer justify-between"
                title="Text color"
                color="#E1E1E1"
                elevation="0"
                @click.stop="
                  openColorPicker(
                    theme.prices.price.color,
                    'prices.price',
                    true
                  )
                "
              >
                <div
                  class="d-flex align-center"
                  :style="`color: ${theme.prices.price.color}`"
                >
                  <span>{{ theme.prices.price.color || "Text color" }}</span>
                </div>
              </v-btn>

              <multiselect
                v-model="theme.prices.price.animation"
                :options="animationOptions"
                selectLabel=""
                deselectLabel=""
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option
                }}</template>

                <template #placeholder>
                  <span
                    class="multiselect__placeholder"
                    title="Select Animation"
                    >Select Animation</span
                  >
                </template>
              </multiselect>

              <input
                v-model="theme.prices.price.delay"
                type="number"
                min="0"
                max="10"
                step="0.5"
                value="0"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div
            class="theme-property pa-3 d-flex flex-wrap justify-space-between align-center"
          >
            <span>Discount text</span>

            <div class="properties d-flex align-center gap-8">
              <v-btn
                class="color-picker-btn radius-15 dark cursor-pointer justify-between"
                title="Text color"
                color="#E1E1E1"
                elevation="0"
                @click.stop="
                  openColorPicker(
                    theme.prices.oldPrice.color,
                    'prices.oldPrice',
                    true
                  )
                "
              >
                <div
                  class="d-flex align-center"
                  :style="`color: ${theme.prices.oldPrice.color}`"
                >
                  <span>{{ theme.prices.oldPrice.color || "Text color" }}</span>
                </div>
              </v-btn>

              <multiselect
                v-model="theme.prices.oldPrice.animation"
                :options="animationOptions"
                selectLabel=""
                deselectLabel=""
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option
                }}</template>

                <template #placeholder>
                  <span
                    class="multiselect__placeholder"
                    title="Select Animation"
                    >Select Animation</span
                  >
                </template>
              </multiselect>

              <input
                v-model="theme.prices.oldPrice.delay"
                type="number"
                min="0"
                max="10"
                step="0.5"
                value="0"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex px-3 text-center mt-4 bottom-sticky">
      <v-btn
        class="radius-10 flex-grow-1 mx-2"
        height="40px"
        color="#E7E7E7"
        @click="resetTheme"
      >
        <span class="font-18 font-500 black--text">Reset</span>
      </v-btn>

      <v-btn
        class="radius-10 flex-grow-1 mx-2"
        height="40px"
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="saveEditsHandler"
      >
        <span class="font-18 font-500 white--text">Done</span>
      </v-btn>

      <v-tooltip top color="white">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="radius-10 mx-2 font-18"
            height="40px"
            width="40px"
            color="primary"
            @click="$emit('replay')"
            v-on="on"
            v-bind="attrs"
          >
            <span class="mdi mdi-replay font-20 d-flex"></span>
          </v-btn>
        </template>
        <span class="black--text font-14 d-inline-block mr-2">
          Replay Preview
        </span>
      </v-tooltip>
    </div>

    <v-dialog
      v-model="colorPickerDialog"
      max-width="530px"
      content-class="white"
      :key="colorPickerDialog + Math.random() * 1000"
    >
      <color-picker
        @close="closeColorPicker"
        :noSubmitMode="true"
        :preSelectedColor="preSelectedColor"
      ></color-picker>
    </v-dialog>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ColorPicker from "@/components/dialogs/ColorPicker.vue";
import animationEffects from "../../helpers/animationEffects";

export default {
  components: {
    Multiselect,
    ColorPicker,
  },
  props: {
    selectedTheme: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      // animationOptions: ["Bounce In", "Fade In", "Pulse", "Rubber Band"],
      animationDelayOptions: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4],
      animationValue: "animate__fadeIn",
      colorPickerDialog: false,
      preSelectedColor: "",
      selectedProperty: "",
      loading: false,
      theme: {},
    };
  },
  computed: {
    animationOptions() {
      return animationEffects.map((effect) => effect.name);
    },
  },
  mounted() {
    this.theme = this.selectedTheme;
  },
  methods: {
    resetTheme() {
      this.loading = true;
      this.$axios
        .put(`${process.env.VUE_APP_THEMES}/${this.theme.numericId}/reset`)
        .then((res) => {
          this.loading = false;

          this.theme = res.data.theme;
          this.$emit("theme-changed", res.data.theme);
          this.$store.dispatch("setFetchedThemes");

          this.$store.dispatch("showSnack", {
            text: "Theme has been reset successfully",
            color: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch("showSnack", {
            text: err.response.data.message || "Something went wrong",
            color: "error",
          });
        });
    },
    saveEditsHandler() {
      this.loading = true;
      this.$axios
        .patch(`${process.env.VUE_APP_THEMES}/${this.theme.numericId}`, {
          ...this.theme,
        })
        .then((res) => {
          this.loading = false;
          this.$store.dispatch("showSnack", {
            text: "Theme edits saved successfully",
            color: "success",
          });

          this.$emit("close");
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch("showSnack", {
            text: err.response.data.message || "Something went wrong",
            color: "error",
          });
        });
    },
    openColorPicker(color, property, deep = false) {
      this.colorPickerDialog = true;
      this.preSelectedColor = color;
      this.selectedProperty = property;
    },
    closeColorPicker(color) {
      this.colorPickerDialog = false;
      if (color) {
        this.preSelectedColor = color;
        // in case of deep property like (theme.container.color)
        if (this.selectedProperty.includes(".")) {
          let selectedPropertyParent = this.selectedProperty.split(".")[0];
          let selectedPropertyChild = this.selectedProperty.split(".")[1];
          this.theme[selectedPropertyParent][selectedPropertyChild].color =
            color;
        } else {
          this.theme[this.selectedProperty].color = color;
        }
      }
      this.selectedProperty = "";
    },
  },
  watch: {
    selectedTheme(newVal) {
      this.theme = newVal;
    },
    theme: {
      deep: true,
      handler(newVal) {
        this.$emit("theme-changed", newVal);
      },
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect__single {
  width: max-content;
}
</style>
