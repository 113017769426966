<template>
  <div class="new-category">
    <div
      class="d-flex align-center justify-space-between px-10 py-3 darkbg-grey"
    >
      <span class="font-22">{{ isEdit ? "Edit Group" : "Add New Group" }}</span>
      <img
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
        class="cursor-pointer"
      />
    </div>
    <div class="bg-grey px-10 py-6">
      <v-form v-model="valid" ref="groupForm" @submit.prevent="submitHandler">
        <base-input
          id="group_name"
          v-model="requestData.name"
          placeholder="Name"
          :hide-details="true"
        ></base-input>

        <!-- <v-select
          class="mt-5"
          label="Slect Preview"
          :items="allPreviews"
          v-model="requestData.preview"
          item-text="title"
          item-value="_id"
        ></v-select> -->

        <div class="d-flex flex-wrap my-5 gap-12">
          <div class="flex-grow-1 d-flex">
            <v-btn
              class="date-picker-btn flex-grow-1"
              color="#E1E1E1"
              @click="openDatePicker"
            >
              <img
                class="mr-2"
                :src="require('@/assets/images/icons/date.svg')"
              />
              <span>{{
                requestData.startDate
                  ? $moment(requestData.startDate).format("DD MMM") ||
                    "Start Date"
                  : "Start Date"
              }}</span>

              <v-icon class="mx-3">mdi-arrow-right</v-icon>

              <span>{{
                requestData.endDate
                  ? $moment(requestData.endDate).format("DD MMM") || "End Date"
                  : "End Date"
              }}</span>
            </v-btn>
          </div>
          <v-btn
            class="reset-btn flex-grow-1"
            color="#E1E1E1"
            @click="resetDate"
            >Reset</v-btn
          >
        </div>

        <!-- <base-date
          class="mt-4"
          label="Start Date"
          @valueHandler="validHandler($event, 'startDate')"
          v-model="requestData.startDate"
          :key="'startData' + keyDateUpdated"
          :rules="[]"
        ></base-date>

        <base-date
          class="mt-4"
          label="End Date"
          @valueHandler="validHandler($event, 'endDate')"
          v-model="requestData.endDate"
          :key="'endData' + keyDateUpdated"
          :rules="[]"
        ></base-date> -->

        <!-- <div class="mt-5">
          <v-btn
            class="color-picker-btn mt-6 radius-15 dark cursor-pointer justify-between"
            height="45px"
            color="#E1E1E1"
            elevation="0"
            @click.stop="showColorPickerDialog = true"
            block
          >
            <div class="d-flex align-center">
              <img
                width="25px"
                class="mx-2"
                :src="require('@/assets/images/icons/paint.svg')"
              />
              <span> {{ !isEdit ? "Select" : "Edit" }} group color </span>
            </div>
            <span
              class="color-picker-btn__color-span"
              :style="`background-color: ${requestData.bg_color}`"
              >{{ requestData.bg_color }}</span
            >
          </v-btn>
        </div> -->

        <v-btn
          class="mt-6 radius-15"
          height="45px"
          color="primary"
          elevation="0"
          block
          type="submit"
          :loading="loading"
          :disabled="loading"
          ><span class="white--text font-16">{{
            isEdit ? "Update" : "Create"
          }}</span></v-btn
        >
      </v-form>

      <v-dialog
        v-if="showColorPickerDialog"
        v-model="showColorPickerDialog"
        max-width="530px"
        content-class="white "
      >
        <color-picker
          :isEdit="isEdit"
          :currentGroup="item"
          :noSubmitMode="true"
          @close="selectedGroupColorHandler"
        ></color-picker>
        <!-- @update="[$emit('updateData'), (newProductDialog = false)]" -->
      </v-dialog>

      <v-dialog
        v-if="showDatePickerDialog"
        v-model="showDatePickerDialog"
        max-width="400"
        content-class="date-picker-dialog-container"
      >
        <date-picker-dialog
          :group="requestData"
          :no-submit-mode="true"
          :key="showDatePickerDialog"
          @close="handleDatePicker"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "../../../plugins/axios";
import ColorPicker from "@/components/dialogs/ColorPicker.vue";
import DatePickerDialog from "./DatePickerDialog.vue";

export default {
  components: { ColorPicker, DatePickerDialog },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    isItem: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: Object,
    },
    type: {
      type: String,
      default: "item",
    },
  },
  data() {
    return {
      loading: false,
      count: "",
      requestData: {
        name: "",
        products: [],
        startDate: "",
        endDate: "",
        themeId: "661559602495c924dafe0a9b",
        type: "template",
        bg_color: "#FF000000",
      },
      products: [],
      valid: false,
      keyDateUpdated: false,
      allPreviews: [],
      showColorPickerDialog: false,
      groupColor: "",
      showDatePickerDialog: false,
    };
  },
  methods: {
    validHandler(val, type) {
      this.requestData[type] = val;
      this.keyDateUpdated = !this.keyDateUpdated;
    },
    async submitHandler() {
      this.loading = true;
      let valid = this.$refs.groupForm.validate();

      if (!valid) {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: "Please fill all fields",
          color: "error",
        });

        return;
      }

      // Note: profile.package is null
      // let availableThemes = this.$store.getters.themes.filter((theme) => {
      //   return this.$store.getters.profile.package[0].theme.includes(+theme.id);
      // });

      // this.requestData.themeId = String("661559602495c924dafe0a9b"); // theme three
        this.requestData.themeId = this.fetchedThemes[0]?._id;

      // filter out the empty or falsy keys
      this.requestData = Object.fromEntries(
        Object.entries(this.requestData).filter(([_, v]) => v)
      );

      let res = await this.$store.dispatch(
        `offersModule/${this.isEdit ? "updateGroup" : "saveGroup"}`,
        this.requestData
      );

      if (res.data?.success || res.success) {
        this.$store.dispatch("showSnack", {
          text: `Group ${this.isEdit ? "Updated" : "Created"} Successfully`,
          color: "success",
        });

        this.$emit("close");
        this.$emit("update");
      } else {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    selectPreview() {
      this.selectedPreview = this.allPreviews.filter(
        (prev) => prev._id == this.requestData.package._id
      )[0];

      this.selectedPackage.numberOfProducts = this.selectedPackage
        .numberOfProducts[0]?.numberOfProducts
        ? this.selectedPackage.numberOfProducts
        : this.selectedPackage.numberOfProducts.map((num) => {
            return {
              numberOfProducts: num,
              pricePerMonth: 0,
              pricePerYear: 0,
              numberOfPreviewsId: 0,
            };
          });
    },
    async getProducts() {
      let { data } = await this.$axios.get(
        `${process.env.VUE_APP_GROUPS}?acceptempty=true`
      );
      this.products = data.products;
    },
    async getAllPreviews() {
      let res = await axios.get(process.env.VUE_APP_PREVIEWS);
      if (res.data.success) {
        this.allPreviews = res.data.data.map((preview) => {
          return { _id: preview._id, title: preview.title };
        });
      }
    },
    selectedGroupColorHandler(selectedColor) {
      if (selectedColor) {
        this.requestData.bg_color = selectedColor;
      }
      this.showColorPickerDialog = false;
    },
    openDatePicker() {
      this.showDatePickerDialog = true;
    },
    handleDatePicker({ startDate, endDate }) {
      this.requestData.startDate = startDate;
      this.requestData.endDate = endDate;

      this.showDatePickerDialog = false;
    },
    resetDate() {
      this.requestData.startDate = "";
      this.requestData.endDate = "";
    },
  },
  async created() {
    // await this.getProducts();
    console.log
    if (this.isEdit) {
      // this.requestData = { ...this.item };
      this.requestData = this.$structuredClone(this.item);

      this.requestData.products = this.requestData.products.map((product) => {
        if (product.item && product.item._id) {
          return product.item._id;
        }
      });
      // this.requestData.endDate = this.requestData.endDate?.slice(0, 10);
      // this.requestData.startDate = this.requestData.startDate?.slice(0, 10);
    }
    await this.getAllPreviews();
  },
};
</script>

<style lang="scss">
.new-category {
  .item-counts {
    span {
      padding: 5px 10px;
      background: #d2e8e3;
      border-radius: 16px;
      transition: 0.3s;
    }
  }
  .theme-active {
    background: $primary !important;
    color: white;
  }

  .color-picker-btn {
    .v-btn__content {
      justify-content: space-between;
    }

    .color-picker-btn__color-span {
      padding: 9px;
      border-radius: 7px;
      text-align: center;
    }
  }
}

.date-picker-btn,
.reset-btn {
  border-radius: 9px;
  border: 0px solid #aaa;
  box-shadow: none;

  img {
    width: 20px;
    height: 20px;
  }
}

.v-dialog.date-picker-dialog-container {
  border-radius: 0;
}
</style>
