<!-- eslint-disable vue/no-unused-components -->
<template>
  <div class="">
    <div
      class="offer-item w-100 mb-2 mt-0"
      :class="'offer-item--' + item.type"
      v-if="showOfferItem"
    >
      <!-- <div
        class="offer-item__drag"
        :class="item.selected ? 'offer-item__drag--selected' : ''"
        v-if="!atDeletedSide"
      >
        <img
          @click="$emit('selectItem', item)"
          class="cursor-pointer"
          v-if="item.selected"
          :src="require('@/assets/images/icons/check-drag.svg')"
        />
      </div> -->

      <div
        class="offer-item__img text-center d-flex align-center justify-center"
        :class="item.show === 'video' ? 'video-item' : ''"
      >
        <slick-carousel
          class="text-center w-100 h-100"
          v-bind="slider"
          v-if="item.show === 'image' && item.images.length > 1"
        >
          <img
            width="100%"
            height="100%"
            :src="
              img.url || require('@/assets/images/icons/item-placeholder.svg')
            "
            class="radius-15 object-cover"
            :style="`object-fit: ${item.imageShowType} !important;`"
            v-for="(img, i) in item.images"
            :key="i"
            :draggable="false"
          />
        </slick-carousel>

        <img
          v-else-if="item.show === 'image' && item.images.length === 1"
          width="100%"
          height="100%"
          class="radius-15 object-cover"
          :src="
            item.images[0].url ||
            require('@/assets/images/icons/item-placeholder.svg')
          "
          :draggable="false"
        />

        <img
          v-else-if="item.show === 'youtube'"
          width="100%"
          height="100%"
          class="radius-15 object-cover"
          :src="extractYoutubeThumbnailUrl(item.links[0].url)"
        />

        <img
          v-else-if="item.show === 'video'"
          width="100%"
          height="100%"
          class="radius-15 object-cover"
          :src="item.video.thumbnail"
        />

        <!-- <v-icon v-else-if="item.show === 'video'" class="font-50"
          >mdi-video</v-icon
        > -->
      </div>

      <div
        class="offer-item__actions-container flex-grow-1 h-100 d-flex align-center"
      >
        <div
          class="offer-item__desc pl-3 pl-xl-7 d-flex align-center justify-space-between"
        >
          <!-- title -->
          <div class="h-100 py-3 flex-grow-1 d-flex align-self-center">
            <span class="d-none d-md-inline item-number align-self-start">
              {{ item.i + 1 }}
            </span>
            <div
              class="offer-item__desc__title flex-grow-1 d-flex align-center"
            >
              <div class="h-100 d-flex flex-column">
                <p
                  class="offer-item__banner font-12 font-700 w-fit-content ma-0"
                  :title="item.banner.text"
                  v-if="item.banner && item.banner.text"
                >
                  {{ item.banner.text }}
                </p>
                <span
                  class="font-18 font-600 d-inline-block"
                  style="word-break: break-word"
                  >{{ item.title }}</span
                >
                <p
                  class="font-16 mb-0 d-none d-sm-block"
                  style="word-break: break-word"
                >
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>

          <!-- mobile price -->
          <!-- <div
            v-if="item.price && item.price != 'undefined'"
            class="offer-item__desc__price d-flex align-center"
          >
            <div
              class="primary pa-2 radius-15 mr-4 price"
              v-if="item.price && item.price != 'undefined'"
            >
              <span class="font-32 font-700 white--text"
                >{{ item.price }}
              </span>
            </div>
          </div> -->

          <div class="d-flex align-center">
            <!-- price -->
            <div
              v-if="
                (item.price && item.price != 'undefined') ||
                (item.oldPrice && item.oldPrice != 'undefined')
              "
              class="offer-item__desc__price d-none d-sm-flex align-center"
            >
              <div
                class="primary pa-2 radius-15 mr-md-4 price"
                v-if="item.price && item.price != 'undefined'"
              >
                <span class="font-32 font-700 white--text"
                  >{{ item.price }}
                </span>
              </div>
              <div
                class="d-none d-md-block offer-item__desc__price__discount"
                v-if="item.oldPrice && item.oldPrice != 'undefined'"
              >
                <span class="font-500 font-18">{{ item.oldPrice }} </span>
              </div>
            </div>

            <hr
              v-if="
                (item.price && item.price != 'undefined') ||
                (item.oldPrice && item.oldPrice != 'undefined')
              "
              class="d-none d-sm-inline-block divider-hr mx-3"
            />

            <!-- timer -->
            <div
              class="timer-parent text-center d-none d-lg-flex align-center"
              v-if="!atDeletedSide"
            >
              <div class="px-3 my-2 timer py-1">
                <img
                  class="cursor-pointer"
                  :src="require('@/assets/images/icons/timelapse 1.svg')"
                  alt=""
                />
                <div
                  v-if="!showCounterInput"
                  class="mx-1 mx-xl-3"
                  @click="toggleShowCounterInput"
                >
                  <span class="no-wrap">{{ counter }} sec</span>
                </div>
                <div v-else class="mx-3">
                  <input
                    id="counterInput"
                    class="counter-input"
                    ref="counterInput"
                    type="number"
                    min="1"
                    v-model="counter"
                    @keyup.enter="$event.target.blur()"
                    @blur="toggleShowCounterInput"
                    @focus="$event.target.select()"
                  />
                </div>
                <div>
                  <div
                    class="d-flex flex-column justify-center cursor-pointer align-center pusrple pa-0"
                  >
                    <v-icon class="pa-0" @click="incrementCounter"
                      >mdi-menu-up</v-icon
                    >
                    <v-icon
                      class="pa-0"
                      @click="decrementCounter"
                      :class="counter == 1 ? 'icon-disabled' : ''"
                      >mdi-menu-down</v-icon
                    >
                  </div>
                </div>
              </div>
            </div>

            <hr class="d-none d-lg-inline-block divider-hr mx-3" />

            <!-- active -->
            <div
              class="offer-item__desc__active text-center"
              v-if="!atDeletedSide"
            >
              <span class="d-block font-18">Active</span>
              <v-switch
                class="mt-0 mr-0"
                :hide-details="true"
                v-model="item.active"
                inset
                @click="toggleItemActivity($event, item)"
              ></v-switch>
            </div>

            <hr class="d-none d-sm-inline-block divider-hr mx-3" />

            <!-- offerPage -->
            <v-tooltip
              v-if="!atDeletedSide && !isEligibleForOfferPage"
              top
              color="warning"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="d-none d-sm-inline-block offer-item__desc__active text-centder pr-3"
                  v-if="!atDeletedSide"
                  v-on="on"
                  v-bind="attrs"
                >
                  <p class="d-block font-18 w-100 ma-0 text-center">
                    Offers Page
                  </p>
                  <v-switch
                    class="mt-0 mr-0"
                    :hide-details="true"
                    v-model="item.isOffer"
                    inset
                    @click="switchHandler($event, item, 'isOffer')"
                    :disabled="!isEligibleForOfferPage"
                  ></v-switch>
                </div>
              </template>
              <span>Must have image, title, and price</span>
            </v-tooltip>

            <div
              class="d-none d-sm-inline-block offer-item__desc__active text-centder pr-3"
              v-else-if="!atDeletedSide"
            >
              <p class="d-block font-18 w-100 ma-0 text-center">Offers Page</p>
              <v-switch
                class="mt-0 mr-0"
                :hide-details="true"
                v-model="item.isOffer"
                inset
                @click="switchHandler($event, item, 'isOffer')"
                :disabled="!isEligibleForOfferPage"
              ></v-switch>
            </div>

            <!-- feature -->
            <!-- <div
            class="offer-item__desc__active text-center"
            v-if="atDeletedSide"
          >
            <span class="d-block font-18">Featured</span>
            <v-switch
              class="mt-0 mr-0"
              :hide-details="true"
              v-model="item.featured"
              inset
              @click="switchHandler($event, item, 'featured')"
            ></v-switch>
          </div> -->
            <!-- restore -->

            <div
              class="d-flex align-center"
              v-if="item.deleted && atDeletedSide"
            >
              <div class="text-center cursor-pointer d0">
                <v-btn icon :disabled="loading" @click="restoreHandler(item)">
                  <img
                    class="cursor-pointer group-arrow"
                    :src="require('@/assets/images/icons/restore.svg')"
                  />
                </v-btn>
              </div>
              <div
                class="text-center cursor-pointer"
                @click="deletePermanentHandler(item)"
              >
                <img
                  width="20px"
                  class="mx-4 mr-4 cursor-pointer"
                  :src="require('@/assets/images/icons/delete-red.svg')"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="offer-item__actions d-flex align-center"
          :class="isGroup ? '' : ' pl-10'"
          v-if="!atDeletedSide"
        >
          <v-tooltip top color="white">
            <template v-slot:activator="{ on, attrs }">
              <img
                v-on="on"
                v-bind="attrs"
                class="mx-2 cursor-pointer d-none d-sm-inline-block"
                :src="
                  require(`@/assets/images/icons/setting-${
                    item.type == 'inactive' || 'white'
                  }.svg`)
                "
                @click="editHandler(item)"
              />
            </template>
            <span class="text-black">Edit Item</span>
          </v-tooltip>

          <v-tooltip top color="white">
            <template v-slot:activator="{ on, attrs }">
              <img
                v-on="on"
                v-bind="attrs"
                class="mx-2 cursor-pointer d-none d-sm-inline-block"
                :src="
                  require(`@/assets/images/icons/delete-${
                    item.type == 'inactive' || 'white'
                  }.svg`)
                "
                @click="deletePermanentHandler(item)"
              />
            </template>
            <span class="text-black">Delete Item</span>
          </v-tooltip>

          <div
            v-if="isGroup"
            class="drag-group cursor-pointer handle"
            @click="$emit('dragItem', item)"
          >
            <img
              class="d-block mx-auto mt-2"
              :src="require('@/assets/images/icons/drag-group.svg')"
            />
          </div>
        </div>
      </div>

      <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
        <delete-item
          @close="closeDeleteModal"
          @getData="deleteProduct"
          :urlApi="deleteApi"
          :itemID="selectedItem"
          :deleteType="deleteType"
        ></delete-item>
      </v-dialog>
    </div>
    <NewModal
      v-if="showForm"
      :type="type"
      :data="item"
      :id="item._id"
      :chosen-group="chosenGroup"
      :is-edit="true"
      @saveProduct="saveProduct"
      @closeModal="closeModal(item._id)"
      :key="item._id"
    />
  </div>
</template>

<script>
import DeleteItem from "../../../components/dialogs/DeleteItem.vue";
import NewModal from "./NewModal.vue";
import _ from "lodash";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { DeleteItem, NewModal },
  props: {
    item: {
      type: Object,
      default: Object,
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    isCheckOpen: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "item",
    },
    selectedTab: {
      type: Number,
      default: 0,
    },
    atDeletedSide: {
      type: Boolean,
      default: false,
    },
    chosenGroup: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      destroyProduct: true,
      loading: false,
      showForm: false,
      deleteDialog: false,
      deleteApi: "",
      productId: "",
      selectedItem: {},
      slider: {
        dots: true,
        arrows: false,
      },
      showOfferItem: true,
      counter: 1,
      deleteType: "archive",
      showCounterInput: false,
    };
  },
  computed: {
    isEligibleForOfferPage() {
      return !!this.item.images && !!this.item.title && !!this.item.price;
    },
  },
  mounted() {
    if (Object.keys(this.item).length) {
      this.counter = Number(this.item.counter || 1);
    }
  },
  methods: {
    updateCounter: _.debounce(async function () {
      if (this.counter < 1) {
        this.$store.dispatch("showSnack", {
          text: "Counter cannot be less than 1 second",
          color: "error",
        });

        this.counter = 1;
      }

      let formData = new FormData();
      formData.append("counter", this.counter);
      this.$store
        .dispatch("offersModule/updateProduct", {
          formData,
          productId: this.item._id,
        })
        .then((res) => {})
        .catch((err) => {
          this.$store.dispatch("showSnack", {
            text: err.message || err.error || "Something went wrong",
            color: "error",
          });
        });
    }, 500),
    async incrementCounter() {
      this.counter = Number(this.counter) + 1;
      await this.updateCounter();
    },
    async decrementCounter() {
      if (this.counter == 1) return;
      this.counter = Number(this.counter) - 1;
      await this.updateCounter();
    },
    toggleShowCounterInput() {
      // if timer input was open, update the counter
      if (this.showCounterInput) {
        this.updateCounter();
      }

      const counterInputNewStatus = !this.showCounterInput;
      this.showCounterInput = counterInputNewStatus;

      // if timer input is opened now, focus the input
      if (counterInputNewStatus) {
        setTimeout(() => this.$refs.counterInput.focus(), 10);
      }
    },
    async switchHandler(_, item, type) {
      //console.log(item, 111, type);
      let formData = new FormData();
      formData.append(type, item[type]);
      await this.$axios.patch(
        `${process.env.VUE_APP_PRODUCTS}/${item._id}`,
        formData
      );
    },
    async toggleItemActivity(_, item, type) {
      let formData = { status: item.active ? "active" : "inactive" };
      // formData.append("status", item.active ? "active" : "inactive");
      await this.$axios
        .put(`${process.env.VUE_APP_PRODUCTS}/${item._id}/status`, formData)
        .then((res) => {
          this.$store.dispatch("offersModule/getAllData");
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("showSnack", {
            text: err.message || err.error || "Something went wrong",
            color: "error",
          });
        });
    },
    async restoreHandler(item) {
      this.loading = true;

      await this.$store.dispatch("offersModule/restoreProduct", item);

      this.loading = false;
    },
    deleteHandler(item) {
      // this.showForm = !this.showForm;
      this.deleteApi = process.env.VUE_APP_PRODUCTS;
      this.deleteDialog = true;
      this.selectedItem = item._id;
      this.deleteType = "archive";
    },
    deletePermanentHandler(item) {
      this.deleteDialog = true;
      this.deleteApi = process.env.VUE_APP_PRODUCTS;
      this.selectedItem = item._id;
      this.deleteType = "delete";
    },

    editHandler(data) {
      this.productId = data._id;
      this.showForm = !this.showForm;
      this.showOfferItem = false;
    },
    async saveProduct(data) {
      await this.$emit("saveProduct", { data, id: this.productId });
      this.showForm = false;
      this.showOfferItem = true;
      return;
    },
    deleteProduct(data) {
      this.$store.dispatch("offersModule/deleteProduct", data);
    },
    closeDeleteModal() {
      this.deleteDialog = false;

      // this.showForm = !this.showForm;
    },
    closeModal() {
      this.showForm = false;
      this.showOfferItem = true;
    },
  },
};
</script>

<style lang="scss">
.timer-parent {
  // border-left: 1px solid #cecccc;
}
.timer {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #ebebeb;
  //width: 30%;
  justify-content: space-evenly;
}

.font-50 {
  font-size: 50px !important;
}

.offer-item {
  border-radius: 15px;
  height: 100px;
  width: max-content;
  max-width: 100%;
  background: #f4f4f4;
  display: inline-flex;
  align-items: center;
  position: relative;

  @media (max-width: $breakpoint-sm) {
    height: 75px;
  }

  &__actions-container {
    width: calc(100% - 100px);
    background: $primary;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .drag-group {
    // position: absolute;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 100%;
    background: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &__drag {
    position: absolute;
    left: 0px;
    transform: translateX(0px);
    transition: 0.3s;
    z-index: 99;

    &--selected {
      transform: translateX(-100%);
    }
  }
  &:hover & {
    &__drag {
      transform: translateX(-100%);
    }
  }
  &__img {
    background: $primary;
    border-radius: 15px;
    height: 100px;
    z-index: 99;
    display: inline-block;
    width: 100px;

    &.video-item {
      background: #ededed;
    }

    @media (max-width: $breakpoint-sm) {
      width: 75px;
      height: 75px;
    }
  }
  &__desc {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background: #f4f4f4;
    height: 100%;
    z-index: 9;
    position: relative;
    left: 0;
    flex-grow: 1;
    justify-content: space-evenly;
    overflow: hidden;

    @media (max-width: $breakpoint-sm) {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &__title {
      & > div {
        span,
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 250px;

          @media (max-width: $breakpoint-md) {
            max-width: 150px;
          }
        }
      }
    }

    &__price {
      // border-left: 1px solid #cecccc;
      // padding-left: 20px;
      // padding-right: 10px;
      min-width: fit-content;
      text-align: center;
      &__discount {
        position: relative;
        width: max-content;
        &::before {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 2px;
          transform: rotate(0deg);
          background: red;
          top: 50%;
        }
      }
    }

    &__active,
    &__feature {
      // padding-left: 20px;
      // padding-right: 20px;
      // border-left: 1px solid #cecccc;

      .v-input {
        position: relative;
        width: fit-content;
        margin: auto !important;
        .v-input--switch__thumb {
          background: white !important;
        }
      }
      .v-input--is-label-active {
        .v-input--switch__track {
          background: $lightgreen !important;
          opacity: 1;
        }
      }
    }
  }

  &__banner {
    min-width: 25px;
    max-width: 150px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    background-color: $primary;
    color: #ffffff;
    padding: 4px;
    border-radius: 6px;
  }

  &__actions {
    position: relative;
    left: 0;
    height: 100%;
    width: fit-content;
  }
  &__restore {
    position: relative;
    height: 100px !important;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background: #e1e1e1;
    width: 120px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    &--delete {
      width: 120px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      background: $lightred;
      height: 100px;
      span {
        color: white !important;
      }
    }
  }

  &--deleted & {
    &__desc {
      padding-right: 0px;
      &__price {
        .price {
          background: #e1e1e1 !important;
          span {
            color: #989898 !important;
          }
        }
      }
    }
  }

  &--inactive & {
    &__desc {
      &__price {
        .price {
          background: #e1e1e1 !important;
          span {
            color: #989898 !important;
          }
        }
      }
    }
    &__actions {
      background: #e1e1e1 !important;
    }
  }

  // @media (max-width: 960px) {
  //   height: auto;
  //   border: 1px solid #e9e9e9;
  //   display: block;
  //   background: #f4f4f4;
  //   width: 100%;
  //   position: relative;
  //   padding-top: 60px;
  //   overflow: unset;
  //   margin: 0px;
  //   &__img {
  //     width: 100px;
  //     height: 100px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     position: absolute;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     top: -50px;
  //     z-index: 99;
  //   }
  //   &__desc {
  //     text-align: center;
  //     padding: 0px;
  //     left: 0px;
  //     border-radius: 0px;
  //     width: 100%;
  //     &__title {
  //       display: block;
  //       width: 100%;
  //     }
  //     &__price,
  //     &__active,
  //     &__feature {
  //       border-left: none;
  //       padding-left: 0px;
  //     }

  //     &__price {
  //       justify-content: center;
  //       border-bottom: 1px solid #e9e9e9;
  //       padding-bottom: 10px;
  //     }
  //     &__active {
  //       display: inline-block;
  //       margin-left: unset;
  //       margin: 10px 10px;
  //       padding: 0px 10px !important;
  //     }
  //     .price {
  //       margin: 0px 10px !important;
  //       margin-right: 10px !important;
  //     }
  //   }
  //   &__actions {
  //     background-color: #f4f4f4 !important;
  //     padding: 0px;
  //     left: 0px;
  //     border-radius: 0px;
  //     justify-content: center;
  //     border-top: 1px solid #e9e9e9;
  //     padding: 20px 0px !important;
  //     padding-left: 0px !important;
  //     padding-right: 0px !important;
  //     width: 100%;
  //   }
  // }
  &--inactive & {
    &__actions {
      background-color: #f4f4f4 !important;
    }
  }
  &__restore {
    position: relative;
    height: 100%;
    padding: 5px 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background: #e1e1e1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slick-track {
    margin: auto;
  }
  .slick-dots {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 14px !important;

    li {
      margin: 0px;

      width: 16px;
      height: 16px;
      button {
        width: 14px;
        height: 14px;
        padding: 2px;
        margin: auto !important;

        &::before {
          font-size: 12px !important;
        }
      }
    }
  }
}

.slick-slide {
  div {
    width: 100px !important;
    height: 100px !important;
  }
}
.item-number {
  color: black;
  border-radius: 5px;
  padding-right: 2px;
  padding-left: 2px;
  font-weight: 700;
  border: 2px solid $primary;
  margin-right: 10px;
  // margin-top: 5px;
}
button.icon-disabled {
  color: #989898 !important;
  cursor: not-allowed !important;
}

.counter-input {
  width: 40px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

.divider-hr {
  height: 55px;
  width: 0px;
  border: none;
  border-left: 1px solid #cecccc;
  background-color: transparent;
}
</style>
