<template>
  <div class="offers-page px-3 py-5 pa-lg-0">
    <div class="max-width offers-page__content">
      <!-- header -->
      <p class="font-30">Media Panel</p>
      <!-- tabs -->
      <div class="d-flex flex-column justify-space-between mt-6 mb-7">
        <v-tabs v-model="selectedTab" class="tab-wrapper">
          <v-tab tag="div" class="tab-all-items">
            <div class="font-22">
              Groups ({{ validCount ? validCount : "0" }})
            </div>
          </v-tab>
          <!-- <v-tab tag="div" class="tab-trash mx-2">
            <div class="font-22"></div>
          </v-tab> -->
        </v-tabs>

        <v-tabs-items class="w-100" v-model="selectedTab">
          <v-tab-item>
            <v-card flat v-if="selectedTab == 0">
              <div
                class="mt-7 d-flex align-center justify-space-between"
                v-if="selectedTab == 0"
              >
                <div
                  class="d-flex align-center cursor-pointer"
                  @click="categoryDialog = true"
                >
                  <div class="primary pa-2 radius-5 mr-2 d-flex align-center">
                    <img
                      width="23"
                      height="23"
                      :src="require('@/assets/images/icons/category.svg')"
                      alt=""
                    />
                  </div>
                  <span class="font-20 black--text">New Group</span>
                </div>
              </div>
              <!-- mobile  -->
              <!-- <div
                class="d-flex align-center justify-space-between mt-3"
                v-if="isMobile"
              >
                <div class="primary radius-30 d-flex align-center px-3">
                  <by-filters @change="tabChanged"></by-filters>
                </div>
                <div
                  class="d-flex align-center cursor-pointer"
                  @click="$router.push('/new-item')"
                  v-if="isMobile"
                >
                  <span class="font-22 font-600">New Template</span>
                  <span
                    class="font-30 d-inline-block px-3 white--text primary ml-2 radius-5"
                    >+</span
                  >
                </div>
              </div> -->
              <groups-tabs
                class="mt-5"
                :atDeletedSide="selectedTab == 1"
                type="template"
                :isTrash="isTrash"
                @updateData="getData"
              ></groups-tabs>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat v-if="selectedTab == 1">
              <trash-tabs
                class="mt-5"
                :atDeletedSide="selectedTab == 1"
                type="template"
                :isTrash="isTrash"
                @updateData="getData"
              ></trash-tabs>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>

    <div
      class="offers-page__fix-nav"
      :class="navActive ? 'offers-page__fix-nav--active' : ''"
    >
      <bottom-nav
        :groups="groups"
        :item="selectedItem"
        @close="navActive = false"
        @updateData="getData"
        :selectedTab="selectedTab"
        @update="[getData(), getGroups()]"
      ></bottom-nav>
    </div>
    <!-- add/edit new -->
    <v-dialog v-model="categoryDialog" max-width="450px">
      <new-category
        @close="categoryDialog = false"
        @update="$store.dispatch('offersModule/getAllData')"
        :isEdit="isEdit"
        :key="categoryDialog"
        type="template"
      ></new-category>
    </v-dialog>

    <v-dialog
      v-model="showPreviewWindow"
      max-width="450px"
      content-class="first-login-preview-dialog"
    >
      <div class="d-flex align-center justify-center close-icon">
        <img
          width="24px"
          height="35px"
          @click="showPreviewWindow = false"
          :src="require('@/assets/images/icons/close.svg')"
          class="cursor-pointer"
        />
      </div>

      <FirstLoginPreviewWindow
        v-if="groupItems.length"
        :group="groupItems[groupItems.length - 1]"
      />
    </v-dialog>
  </div>
</template>

<script>
import GroupsSection from "@/views/offers/components/Groups";
import GroupsTabs from "@/views/offers/components/GroupsTabs";
import TrashTabs from "@/views/offers/components/TrashTabs";
import ByFilters from "@/views/offers/components/Filters.vue";
import BottomNav from "@/views/offers/components/BottomNav.vue";
import NewCategory from "./components/NewCategory.vue";
import FirstLoginPreviewWindow from "./components/FirstLoginPreviewWindow.vue";
// import OfferItem from "./components/OfferItem.vue";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    // eslint-disable-next-line vue/no-unused-components
    GroupsSection,
    // eslint-disable-next-line vue/no-unused-components
    GroupsTabs,
    // eslint-disable-next-line vue/no-unused-components
    TrashTabs,
    // eslint-disable-next-line vue/no-unused-components
    ByFilters,
    BottomNav,
    NewCategory,
    FirstLoginPreviewWindow,
    // OfferItem,
  },
  data: () => ({
    tabs: [
      { title: "All Category", count: 0, value: "all" },
      // { title: "Active Category", count: 0, value: "active" },
      // { title: "Inactive", count: 0, value: "inactive" },
      { title: "Trash", value: "deleted" },
    ],
    data: [],
    showPreviewWindow: false,
    itemsClone: [],
    type: "",
    typeData: { title: "All Templates" },
    selectedTab: 0,
    count: 6,

    selectedPage: 1,
    navActive: false,
    categoryDialog: false,
    isEdit: false,
    isEditTemplate: false,

    isEditItem: false,
    updateDataKey: false,
    groups: [],
    selectedItem: {},
    itemSeletedKey: false,
    isCheckOpen: false,
    isTrash: false,
    plans: [],
    allItemsCount: 0,
  }),
  computed: {
    validCount() {
      return this.$store.getters["offersModule/validCount"];
    },
    groupItems() {
      return this.$store.getters["offersModule/allData"];
    },
  },
  methods: {
    dragItemHandler(item, group, type) {
      this.navActive = true;
      this.selectedItem = item;
      if (type == "group") {
        this.data.forEach((i) => {
          i.selected = false;
        });

        this.groups.forEach((g) => {
          if (group._id == g._id) {
            g.products.forEach((i) => {
              if (i.item._id == item._id) {
                i.item.selected = true;
              } else {
                i.item.selected = false;
              }
            });
          }
        });
      } else {
        this.groups.forEach((g) => {
          g.products.forEach((i) => {
            if (i.item) {
              i.item.selected = false;
            }
          });
        });
        this.data.forEach((i) => {
          if (i._id == item._id) {
            i.selected = true;
          } else {
            i.selected = false;
          }
        });
      }
      this.itemSeletedKey = !this.itemSeletedKey;
    },
    selectItemHandler(item) {
      let checkOpenFromGroup = [];
      this.groups.forEach((g) => {
        g.products.forEach((i) => {
          if (i.item._id == item._id) {
            i.item.selected = !i.item.selected;
          }
        });
      });

      this.groups.forEach((g) => {
        g.products.forEach((i) => {
          if (i.item.selected) {
            checkOpenFromGroup.push(i.item);
          }
        });
      });
      this.selectedItem = checkOpenFromGroup;

      let checkOpen = [];
      if (this.selectedTab == 3) {
        this.data.forEach((i) => {
          if (i._id == item._id) {
            i.selected = !i.selected;
          }
        });

        let checkOpen = this.data.filter((item) => item.selected);
        this.selectedItem = checkOpen;
      }
      if (checkOpen.length > 0 || checkOpenFromGroup.length > 0) {
        this.isCheckOpen = true;
        this.navActive = true;
      } else {
        this.isCheckOpen = false;
        this.navActive = false;
      }
      this.itemSeletedKey = !this.itemSeletedKey;
    },
    pageHandler(tab) {
      if (tab == "templates") {
        this.$router.push("/" + tab);
      } else {
        this.$router.push("/");
      }
    },

    async tabChanged(event) {
      //console.log("tabChanged");
      this.navActive = false;
      this.newItemDialog = false;
      await this.$store.dispatch("offersModule/getAllData", event);
      // this.isTrash = event == "deleted" ? true : false;
    },
    editHandler(item) {
      this.newTemplateDialog = true;
      this.isEditTemplate = true;
      this.selectedItem = { ...item };
    },
    restorGetHandler() {
      this.selectedTab = 0;
      // this.getData();
    },
    // eslint-disable-next-line no-unused-vars
    async getData(filter = this.selectedTab == 0 ? "all" : "deleted") {
      // //console.log("test template");
      // this.navActive = false;
      // this.newTemplateDialog = false;
      // this.data = [];
      // let { data } = await this.$axios.get("/products/user/template", {
      //   params: {
      //     filters: filter,
      //   },
      // });
      // // //console.log(data);
      // // console.warn("test");
      // if (data.success) {
      //   // //console.log("test");
      //   this.$store.commit("SET_GROUP_ITEMS", data.Categories);
      //   this.data = data.Categories;
      //   this.allItemsCount = data.validCount;
      // }
    },
    async getPlans() {
      let { data } = await this.$axios.get(process.env.VUE_APP_PLANS);
      if (data.success) {
        this.plans = data.plans;
      }
    },
    async getGroups(filtered) {
      this.groups = [];
      let res;
      if (filtered == "all") {
        res = await this.$axios.get(
          `${process.env.VUE_APP_GROUPS}?acceptempty=true`
        );
        //console.log(res);
      } else {
        res = await this.$axios.get(
          `${
            process.env.VUE_APP_GROUPS + filtered ? "?filter=" + filtered : ""
          }`
        );
      }
      if (res.data.success) {
        this.groups = res.data.groups.map((group) => {
          return { ...group, active: false };
        });
      }
      // let activeGroups = this.groups.filter((group) => !group.expired);
      // this.allItemsCount = activeGroups.length;
      // this.tabs[1].count = activeGroups.length;
      // this.tabs[2].count = this.groups.length - activeGroups.length;
    },
  },
  async mounted() {
    // await this.getData();
    await this.$store.dispatch("offersModule/getAllData");
    await this.$store.dispatch("offersModule/getAllBanners");
    await this.$store.dispatch("offersModule/getAllTrash");

    if (this.profile) {
      const createdProductAndPreview =
        Number(this.profile.planDetails.productsUsed) > 0 &&
        Number(this.profile.planDetails.previewsUsed) > 0;

      if (this.profile.isFirstLogIn) {
        if (this.groupItems.length && createdProductAndPreview) {
          this.showPreviewWindow = true;

          this.$store.dispatch("alterFirstLogin");
        } else {
          this.$router.push("/welcome");
        }
      }
    }
  },
  beforeRouteEnter(_, _2, next) {
    if (localStorage.getItem("role") == "user") {
      next();
    } else {
      next("/admin/packages");
    }
  },
  watch: {
    // selectedTab: {
    //   handler(newValue) {
    //     //console.log("newValue", newValue);
    //   },
    //   immediate: true,
    // },
    async selectedTab(to) {
      if (to) {
        await this.$store.dispatch("offersModule/getAllTrash", "deleted");
      } else {
        await this.$store.dispatch("offersModule/getAllData");
      }
    },
  },
};
</script>

<style lang="scss">
.tab-wrapper {
  width: 100% !important;
  overflow: initial !important;
}

.offers-page {
  padding: 0px;
  position: relative;

  @media (max-width: $breakpoint-lg) {
    padding: 0px 15px;
  }

  &__content {
    min-height: 100vh;
  }

  .v-tab {
    margin: 0px !important;
    background: #f4f4f4;
    transition: 0.3s;
    border-radius: 15px;
    margin-right: 15px !important;

    // div {
    //   color: black;
    // }

    &--active {
      background: $primary;

      div {
        color: white;
      }
    }
  }

  .v-tab {
    &:before {
      display: none !important;
    }
  }

  .v-tab--active:hover:before,
  .v-ripple__container {
    display: none;
  }

  .v-tabs-slider-wrapper {
    display: none;
  }

  .v-input--selection-controls__input {
    margin-right: 0px;
  }

  &__fix-nav {
    position: sticky;
    bottom: 10px;
    height: 50px;
    width: 91%;
    z-index: 99;
    left: 0px;
    background: $primary;
    border-radius: 15px;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);

    &--active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }
  }

  @media (max-width: 600px) {
    padding: 0px 10px;

    &__content {
      // margin-top: 100px;
      padding: 0px 5px;
    }

    .line {
      width: 1px;
      background: $lightprimary;
      height: 30px;
      margin: 10px 15px;
    }
  }
}

.tab-all-items {
  &:hover,
  &.v-tab--active {
    background: #0195a9 !important;
    color: white !important;
  }

  color: black !important;
}

.tab-trash {
  min-width: auto !important;
  &:hover,
  &.v-tab--active {
    background-color: $error !important;
    color: white;

    div {
      background-image: url("../../assets/images/icons/trash-icon-white.svg");
    }
  }

  &:not(.v-tab--active) {
    color: black !important;
  }

  div {
    background-image: url("../../assets/images/icons/trash-icon-red.svg");
    width: 24px;
    height: 24px;
  }
}

.first-login-preview-dialog {
  position: relative;
  width: 100%;
  max-width: 1090px !important;
  margin: 30px;
  height: 100%;
  max-height: 624px !important;
  border: 30px solid black;
  background-color: white;
  overflow: visible;

  &::after {
    content: "";
    position: absolute;
    top: calc(100% + 15px);
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    border-radius: 10px;
    width: 20%;
    height: 29px;
  }

  .close-icon {
    position: absolute;
    top: -44px;
    right: -44px;
    z-index: 10;
    width: 44px;
    height: 44px;
    border-radius: 44px;
    background-color: rgba(217, 217, 217, 1);
  }
}
</style>
