<template>
  <div>
    <div
      class="d-flex align-center justify-space-between px-10 py-3 darkbg-grey"
    >
      <span class="font-22">Duplicate Item ({{ item.title }})</span>
      <img
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
        class="cursor-pointer"
      />
    </div>
    <div class="bg-grey px-10 py-6">
      <v-form
        v-model="valid"
        ref="dubplicateForm"
        @submit.prevent="submitHandler"
      >
        <v-text-field
          v-model="obj.title"
          label="Item Title"
          dense
          :hide-details="true"
        ></v-text-field>

        <v-btn
          class="mt-6 radius-15"
          height="45px"
          color="primary"
          elevation="0"
          block
          type="submit"
          ><span class="white--text font-16">Create</span></v-btn
        >
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  data: () => ({
    obj: {},
    valid: false,
  }),
  methods: {
    async submitHandler() {
      let valid = this.$refs.dubplicateForm.validate();
      if (!valid) {
        return;
      }

      // TODO: CHECK WITH BACKEND
      let res = await this.$axios.post("/products/duplicate", {
        title: this.obj.title,
        id: this.item._id,
      });
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: "Duplicated Successfully",
          color: "success",
        });
        this.$emit("update");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    async getGroups() {
      let { data } = await this.$axios.get(`${process.env.VUE_APP_GROUPS}?acceptempty=true`);
      this.products = data.products;
    },
  },
  async created() {
    // await this.getProducts();
  },
};
</script>

<style lang="scss" scoped>
.item-counts {
  span {
    padding: 5px 10px;
    background: #d2e8e3;
    border-radius: 16px;
    transition: 0.3s;
  }
}
.theme-active {
  background: $primary !important;
  color: white;
}
</style>
