<template>
  <div class="offers-groups">
    <div class="text-center" v-if="groups.length == 0 && atDeletedSide == 0">
      <span class="font-20 black--text"
        >No groups yet... Click on
        <span
          class="primary--text font-600 cursor-pointer"
          @click="$emit('categoryDialog')"
          >Create New Category Above</span
        ></span
      >
    </div>
    <template v-for="(group, j) in groups">
      <div
        :key="j"
        class="offers-groups__group d-flex align-center justify-space-between"
      >
        <span class="font-20 black--text"
          >{{ group.name }} ({{ group.products.length + " Products" }})</span
        >
        <div
          class="d-flex align-center justify-space-between"
          v-if="!atDeletedSide"
        >
          <!-- date -->
          <div
            class="d-flex align-center mr-10 cursor-pointer"
            @click="editHandler(group)"
          >
            <img
              class="mr-2"
              :class="checkDate(group.endDate)"
              :src="require('@/assets/images/icons/date.svg')"
            />
            <span class="font-18 black--text font-500" v-if="group.startDate">
              {{ group.startDate.slice(0, 10) }} /
              <span v-if="group.endDate">
                {{ group.endDate.slice(0, 10) }}
              </span>
            </span>
          </div>
          <!-- themes -->
          <div
            class="d-flex align-center px-3 py-2 radius-15 white mr-10 cursor-pointer"
            @click="selectThemeHandler(group)"
          >
            <span class="black--text font-16 d-inline-block mr-2">
              {{ themes.find((theme) => theme.id == group.theme).title }}
            </span>
            <img :src="require('@/assets/images/icons/dots.svg')" alt="" />
          </div>
          <!-- add new -->
          <div class="d-flex align-center">
            <img
              @click="addNewHandler(group)"
              width="20px"
              class="mx-2 mr-4 cursor-pointer"
              :src="require('@/assets/images/icons/add.svg')"
            />
          </div>
          <!-- show items -->
          <div class="d-flex align-center">
            <img
              @click="deleteHandler(group)"
              width="20px"
              class="mx-2 mr-4 cursor-pointer"
              :src="require('@/assets/images/icons/delete-black.svg')"
            />
            <img
              @click="editHandler(group)"
              width="20px"
              class="mx-2 mr-4 cursor-pointer"
              :src="require('@/assets/images/icons/setting-black.svg')"
            />
            <img
              class="cursor-pointer group-arrow"
              :class="group.open ? 'group-arrow--active' : ''"
              :src="require('@/assets/images/icons/arrow-right.svg')"
              @click="toggleGroup(j)"
            />
          </div>
        </div>

        <div class="d-flex align-center justify-space-between" v-else>
          <!-- show items -->
          <div class="d-flex align-center">
            <img
              class="cursor-pointer group-arrow"
              :src="require('@/assets/images/icons/restore.svg')"
              @click="restoreHandler(group)"
              v-if="group.deleted"
            />
            <img
              @click="deletePermanentHandler(group)"
              v-if="group.deleted"
              width="20px"
              class="mx-2 mr-4 cursor-pointer"
              :src="require('@/assets/images/icons/delete-red.svg')"
            />

            <img
              class="cursor-pointer group-arrow"
              :class="group.open ? 'group-arrow--active' : ''"
              :src="require('@/assets/images/icons/arrow-right.svg')"
              @click="toggleGroup(j)"
            />
          </div>
        </div>
      </div>
      <transition name="fade" mode="out-in" :key="j">
        <div class="py-10 pl-14 pr-5 fuck" v-if="group.open">
          <v-row :key="itemSeletedKey">
            <template
              v-if="
                group.products.length > 0 &&
                typeof group.products[0].item == 'object'
              "
            >
              <draggable
                @change="orderChangeHandler(group, j)"
                v-model="group.products"
                group="people"
                style="display: contents"
                class="row mt-4 justify-start"
                handle=".handle"
                :move="checkMove"
                ghost-class="ghost"
              >
                <v-col
                  cols="12"
                  class="pa-0 px-1 ma-0"
                  :class="isMobile ? ' mb-3' : ''"
                  v-for="(item, i) in group.products"
                  :key="i"
                >
                  <offer-item
                    :item="{ ...item.item, j }"
                    :class="isMobile ? 'mb-14' : ''"
                    @getData="$emit('updateData')"
                    @dragItem="$emit('dragItem', item.item, group, 'group')"
                    :isGroup="true"
                    @selectItem="$emit('selectItem', item.item)"
                    @restorGetHandler="restorGetHandler"
                    :type="type"
                    @edit="editHandlerItem"
                    :atDeletedSide="atDeletedSide"
                  ></offer-item>
                </v-col>
              </draggable>
            </template>
            <div class="text-center" v-else>
              <span class="font-24 black--text">
                {{
                  atDeletedSide ? "No items added yet" : "No Products Yet..."
                }}
              </span>
            </div>
          </v-row>

          <!-- <div class="text-center" v-else>
          <span class="font-24 black--text">No Products Yet...</span>
        </div> -->
        </div>
      </transition>
    </template>

    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <custom-delete
        @close="deleteDialog = false"
        @getData="$emit('updateData')"
        :urlApi="urlApi"
        :itemID="selectedItem._id"
      ></custom-delete>
    </v-dialog>

    <!-- add/edit new -->
    <v-dialog v-model="categoryDialog" max-width="450px">
      <new-category
        @close="categoryDialog = false"
        @update="$emit('updateData')"
        :isEdit="true"
        :item="selectedItem"
        :key="categoryDialog"
      ></new-category>
    </v-dialog>

    <!-- add/edit new product -->
    <v-dialog
      v-model="newProductDialog"
      max-width="530px"
      content-class="white "
      :key="newProductDialog"
    >
      <new-product
        @close="newProductDialog = false"
        :isEdit="isEditProduct"
        :key="keyProductDialog"
        :selectedItem="selectedItem"
        @update="[$emit('updateData'), (newProductDialog = false)]"
        :chosenGroup="chosenGroup"
      ></new-product>
    </v-dialog>

    <!-- select theme -->
    <select-theme
      @close="selectThemeDialog = false"
      v-if="selectThemeDialog"
      :item="selectedItem"
      :key="selectThemeDialog"
      @update="updateHandler"
      :isTemplate="type == 'template'"
    ></select-theme>
  </div>
</template>

<script>
import NewCategory from "./NewCategory.vue";
import NewProduct from "@/components/dialogs/NewProduct.vue";
import SelectTheme from "../../../components/dialogs/SelectTheme.vue";
import OfferItem from "./OfferItem.vue";
import { mapGetters } from "vuex";
export default {
  components: { NewCategory, OfferItem, SelectTheme, NewProduct },
  props: {
    template: {
      type: Array,
      defautl: () => [],
    },
    type: {
      type: String,
      defautl: "item",
    },
    atDeletedSide: {
      type: Boolean,
      defautl: false,
    },
  },
  data: () => ({
    deleteDialog: false,
    categoryDialog: false,
    navActive: false,
    selectedItem: {},
    selectThemeDialog: false,
    itemSeletedKey: false,
    newProductDialog: false,
    isEditProduct: false,
    keyProductDialog: false,
    chosenGroup: "",
    groups: [],
    urlApi: "",
  }),

  methods: {
    checkMove: function (e) {
      // return false if not the context
      return e.relatedContext.element._id == e.relatedContext.element._id;
    },
    restorGetHandler() {
      this.$emit("updateData");
    },
    async restoreHandler(item) {
      let response = await this.$axios.post(
        `${process.env.VUE_APP_GROUPS}/${item._id}/status`,
        {
          status: "restored",
        }
      );
      //console.log(response);
      if (response.status == 200) {
        this.$emit("updateData");
      }
    },
    deletePermanentHandler(item) {
      this.deleteDialog = true;
      //       //console.log(item);
      this.urlApi = process.env.VUE_APP_GROUPS;
      this.selectedItem = item;
    },
    deleteHandler(item) {
      this.deleteDialog = true;
      this.urlApi = process.env.VUE_APP_GROUPS;
      this.selectedItem = item;
    },
    editHandler(item) {
      this.categoryDialog = true;
      this.selectedItem = item;
    },
    editHandlerItem(item) {
      this.selectedItem = {};
      this.isEditProduct = true;
      this.newProductDialog = true;
      this.keyProductDialog = !this.keyProductDialog;
      this.selectedItem = { ...item };
    },
    selectThemeHandler(item) {
      this.selectThemeDialog = true;
      //       //console.log(item);
      this.selectedItem = item;
    },
    toggleGroup(i) {
      this.groups.forEach((item, idx) => {
        if (idx == i) {
          item.open = !item.open;
        }
      });
    },
    updateHandler() {
      this.selectThemeDialog = false;
      this.$emit("updateData");
    },
    async orderChangeHandler(group, idx) {
      let newProducts = this.groups[idx].products.map((item) => item.item._id);
      let sortObj = {
        groupId: group._id,
        products: newProducts,
      };
      await this.$axios.post(`/products/group/sort`, sortObj);
    },
    checkDate(date) {
      if (date) {
        let itemDate = date.slice(0, 10);
        let today = new Date().toISOString();
        let todayTime = today.slice(0, 10);
        if (itemDate > todayTime) {
          return "";
        } else {
          return " filter-grey";
        }
      } else {
        return " filter-grey";
      }
    },
    addNewHandler(group) {
      this.chosenGroup = group;
      this.newProductDialog = true;
      this.isEditProduct = false;
      this.selectedItem = {};
    },
  },
  watch: {
    template: {
      handler(val) {
        this.groups = val.map((item) => {
          return {
            ...item,
            open: false,
          };
        });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["themes"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>

<style lang="scss">
.offers-groups {
  &__group {
    background: #f2f2f2;
    width: 95%;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 15px 25px;
  }

  .group-arrow {
    transition: 0.3s;

    &--active {
      transform: rotate(90deg);
    }
  }
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
