<template>
  <div class="bg-red-400 mt-4 mb-3 px-0 px-md-9">
    <div class="pa-0 ma-0 d-flex">
      <div class="create-modal w-100">
        <!-- <v-container> -->
        <v-tabs v-model="selectedTab" class="tab-wrapper mb-3">
          <v-tab tag="div" class="tab-item">
            <!-- <div> -->
            <img
              :src="
                selectedTab == 0
                  ? require('@/assets/images/icons/item-icon-active.svg')
                  : require('@/assets/images/icons/item-icon.svg')
              "
              alt="item"
            />
            Item
            <!-- </div> -->
          </v-tab>
          <div
            class="h-100"
            @click.prevent="() => (profile.plan.isFree ? showProTip() : null)"
          >
            <v-tab
              :disabled="profile.plan.isFree"
              tag="div"
              class="tab-youtube h-100"
            >
              <!-- <div> -->
              <img
                :src="
                  selectedTab == 1
                    ? require('@/assets/images/icons/youtube-icon-active.svg')
                    : require('@/assets/images/icons/youtube-icon.svg')
                "
                alt="youtube"
              />
              Youtube

              <img
                v-if="profile.plan.isFree"
                class="pro-flag ml-2"
                width="20px"
                height="20px"
                :src="require('@/assets/images/icons/pro-flag.svg')"
              />

              <!-- </div> -->
            </v-tab>
          </div>

          <div
            class="h-100"
            @click.prevent="() => (profile.plan.isFree ? showProTip() : null)"
          >
            <v-tab
              :disabled="profile.plan.isFree"
              tag="div"
              class="tab-video h-100"
            >
              <!-- <div> -->
              <img
                :src="
                  selectedTab == 2
                    ? require('@/assets/images/icons/video-icon.svg')
                    : require('@/assets/images/icons/video-icon.svg')
                "
                alt="video"
              />
              Video

              <img
                v-if="profile.plan.isFree"
                class="pro-flag ml-2"
                width="20px"
                height="20px"
                :src="require('@/assets/images/icons/pro-flag.svg')"
              />

              <!-- </div> -->
            </v-tab>
          </div>
        </v-tabs>
        <!-- </v-container> -->

        <v-tabs-items v-model="selectedTab">
          <!-- Item -->
          <v-tab-item :value="0">
            <div class="py-3">
              <div class="img-uploader">
                <v-row class="h-100 mx-0">
                  <v-col class="col-img h-100" md="12">
                    <div class="relative cursor-pointer h-100">
                      <img
                        v-if="imageFormUpload.imgDataUrl || imageFormUpload.url"
                        width="15px"
                        class="upload-file-close"
                        @click.stop="
                          [deleteImageOnClient(), deleteImageHandler()]
                        "
                        :src="require('@/assets/images/icons/close.svg')"
                      />
                      <img
                        v-if="imageFormUpload.imgDataUrl || imageFormUpload.url"
                        width="100%"
                        height="100%"
                        :style="`object-fit: ${schema.imageShowType} !important;`"
                        :src="imageFormUpload.imgDataUrl || imageFormUpload.url"
                        alt=""
                        class="selected-image"
                      />
                      <NewUploadBtn
                        v-else
                        text="Logo image"
                        class="upload-logo w-100 h-100"
                        @imagePicked="uploadImageHandler"
                      >
                      </NewUploadBtn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="py-3">
              <v-row>
                <v-col cols="12">
                  <h1 class="font-16">Image Show Type</h1>

                  <div class="d-flex gap-10">
                    <v-tooltip top color="white">
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          v-on="on"
                          v-bind="attrs"
                          width="35px"
                          height="35px"
                          :src="
                            schema.imageShowType === 'cover'
                              ? IconCoverSelected
                              : IconCoverUnselected
                          "
                          alt="cover"
                          class="cursor-pointer"
                          @click="selectShowType('cover', 'imageShowType')"
                        />
                      </template>
                      <span class="black--text font-16 d-inline-block mr-2">
                        cover
                      </span>
                    </v-tooltip>

                    <v-tooltip top color="white">
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          v-on="on"
                          v-bind="attrs"
                          width="35px"
                          height="35px"
                          :src="
                            schema.imageShowType === 'contain'
                              ? IconContainSelected
                              : IconContainUnselected
                          "
                          alt="contain"
                          class="cursor-pointer"
                          @click="selectShowType('contain', 'imageShowType')"
                        />
                      </template>
                      <span class="black--text font-16 d-inline-block mr-2">
                        contain
                      </span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="py-3">
              <v-row class="justify-space-between">
                <v-col cols="12" sm="12" md="6" lg="6">
                  <div class="d-flex align-center input-container">
                    <input
                      v-model="schema.title"
                      class="modal-input px-2 flex-grow-1"
                      type="text"
                      placeholder="Name"
                      @input="handleLength(60, 'title', 'titleLength')"
                    />
                    <span class="counter-validation">
                      60/
                      <span style="">
                        {{ titleLength }}
                      </span>
                    </span>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3">
                  <div class="d-flex align-center input-container">
                    <span class="counter-validation currency-symbol font-16">
                      {{ profile.currency ? profile.currency.symbol : "" }}
                    </span>
                    <input
                      v-model="schema.price"
                      class="modal-input px-2 flex-grow-1"
                      type="number"
                      placeholder="Price"
                    />
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="3">
                  <div class="d-flex align-center input-container">
                    <span class="counter-validation currency-symbol font-16">
                      {{ profile.currency ? profile.currency.symbol : "" }}
                    </span>
                    <input
                      v-model="schema.oldPrice"
                      class="modal-input px-2 flex-grow-1"
                      type="number"
                      placeholder="Old Price"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row class=" ">
                <v-col cols="12" sm="12">
                  <div class="pa-0 my-2 d-flex input-container">
                    <input
                      @input="
                        handleLength(100, 'description', 'descriptionLength')
                      "
                      v-model="schema.description"
                      class="modal-input px-2 flex-grow-1"
                      type="text"
                      placeholder="Description"
                    />
                    <span class="counter-validation">
                      100/
                      <span style="">
                        {{ descriptionLength }}
                      </span>
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="modal-banner mt-5">
              <h2 class="font-20">Banner</h2>
              <p class="add font-16">
                Add your custom from
                <span @click="bannerInput = true">here</span>
              </p>
              <v-row class="my-2">
                <BannerItem
                  v-for="(banner, i) in banners"
                  :id="banner._id"
                  :key="i"
                  :banner="banner"
                  :index="i"
                  :ref="`banner-${i}`"
                  :class="{ 'selected-banner': banner._id == schema.bannerId }"
                  class="banner-item"
                  @click.native="selectedBanner(`banner-${i}`, banner)"
                  @changeValue="changeValue"
                  @deleteBanner="showConfirmDeleteBannerDialog"
                />
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  sm="6"
                  class="relative d-flex justify-end align-center py-1 cursor-pointer"
                >
                  <v-text-field
                    v-if="bannerInput"
                    class="banner-class my-0"
                    v-model="bannerInputText"
                    label="Enter new banner"
                    solo
                    append-outer-icon="mdi-check-circle"
                    clear-icon=""
                    clearable
                    :hide-details="true"
                    type="text"
                    @click:append-outer="createBanner(bannerInputText)"
                    @keypress.enter="createBanner(bannerInputText)"
                    @click:clear="bannerInputText = ''"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <div class="py-3">
              <div class="d-block">
                <h3 class="font-20">Product Preview Timing</h3>
                <div class="pa-1 my-5 timer w-fit-content">
                  <img
                    class="cursor-pointer"
                    :src="require('@/assets/images/icons/timelapse 1.svg')"
                    alt=""
                  />
                  <span class="mx-3">{{ counter }} sec</span>
                  <div>
                    <div
                      class="d-flex flex-column justify-center cursor-pointer align-center pusrple pa-0"
                    >
                      <v-icon class="pa-0" @click="incrementCounter"
                        >mdi-menu-up</v-icon
                      >
                      <v-icon class="pa-0" @click="decrementCounter"
                        >mdi-menu-down</v-icon
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isEligibleForOfferPage">
                <div class="my-6">
                  <h3 class=" ">
                    Show In Offers Page?
                    <span
                      v-if="profile.plan.isFree"
                      class="cursor-pointer"
                      @click="
                        $store.dispatch('setProTipDialog', {
                          show: true,
                          title: 'Offers page',
                          description:
                            'Engage Customers Online with Our Digital Offers Page. Showcase your deals effectively in a vibrant, online format.',
                        })
                      "
                    >
                      <img
                        class="pro-flag ml-2"
                        width="20px"
                        height="20px"
                        :src="require('@/assets/images/icons/pro-flag.svg')"
                      />
                    </span>
                  </h3>
                  <span>
                    <v-switch
                      class="my-5 mr-0 ml-1"
                      :hide-details="true"
                      v-model="schema.isOffer"
                      inset
                    ></v-switch>
                  </span>
                </div>
              </div>
            </div>
          </v-tab-item>

          <!-- youtube video -->
          <v-tab-item :value="1">
            <v-container class="">
              <v-row class="justify-space-between">
                <v-col cols="12" class="pa-0 d-flex input-container">
                  <ValidationProvider rules="yt_url" v-slot="v" class="w-100">
                    <input
                      v-model="schema.links[0].url"
                      class="modal-input w-100 h-100 py-0 px-2 flex-grow-1"
                      type="text"
                      placeholder="Video Link"
                      required
                    />
                    <span class="danger--text mb-3 font-12 d-block">{{
                      v.errors[0]
                    }}</span>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row class="d-block">
                <v-col class="py-0 my-5 py-1">
                  <div class="w-fit-content timer px-1">
                    <img
                      class="cursor-pointer"
                      :src="require('@/assets/images/icons/timelapse 1.svg')"
                      alt=""
                    />
                    <span class="mx-3 w-fit-content">{{ counter }} sec</span>
                    <div>
                      <div
                        class="d-flex flex-column justify-center cursor-pointer align-center pusrple pa-0"
                      >
                        <v-icon class="pa-0" @click="incrementCounter"
                          >mdi-menu-up</v-icon
                        >
                        <v-icon class="pa-0" @click="decrementCounter"
                          >mdi-menu-down</v-icon
                        >
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row class="d-block">
                <v-col cols="12">
                  <v-btn
                    color="white"
                    class="more-details-btn no-box-shadow"
                    @click="showDetails = !showDetails"
                  >
                    <img
                      :src="require('@/assets/images/icons/more-details.svg')"
                      alt="More Details"
                    />
                    <span class="ml-2">More Details</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>

            <v-container v-if="showDetails">
              <v-container class="">
                <v-row class="justify-space-between">
                  <v-col
                    cols="12"
                    lg="7"
                    md="6"
                    sm="8"
                    class="pa-0 my-2 d-flex input-container"
                  >
                    <input
                      v-model="schema.title"
                      class="modal-input px-2 flex-grow-1"
                      type="text"
                      placeholder="Name"
                      @input="handleLength(60, 'title', 'titleLength')"
                    />
                    <span class="counter-validation">
                      60/
                      <span style="">
                        {{ titleLength }}
                      </span>
                    </span>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="2"
                    md="3"
                    sm="2"
                    class="pa-0 ma-2 d-flex input-container"
                  >
                    <span class="counter-validation currency-symbol">
                      {{ profile.currency ? profile.currency.symbol : "" }}
                    </span>
                    <input
                      v-model="schema.price"
                      class="modal-input px-2 flex-grow-1"
                      type="number"
                      placeholder="Price"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="2"
                    md="3"
                    sm="2"
                    class="pa-0 my-2 d-flex input-container"
                  >
                    <span class="counter-validation currency-symbol">
                      {{ profile.currency ? profile.currency.symbol : "" }}
                    </span>
                    <input
                      v-model="schema.oldPrice"
                      class="modal-input px-2 flex-grow-1"
                      type="number"
                      placeholder="Old Price"
                    />
                  </v-col>
                </v-row>
                <v-row class=" ">
                  <v-col
                    cols="12"
                    sm="12"
                    class="pa-0 my-2 d-flex input-container"
                  >
                    <input
                      @input="
                        handleLength(100, 'description', 'descriptionLength')
                      "
                      v-model="schema.description"
                      class="modal-input px-2 flex-grow-1"
                      type="text"
                      placeholder="Description"
                    />
                    <span class="counter-validation">
                      100/
                      <span style="">
                        {{ descriptionLength }}
                      </span>
                    </span>
                  </v-col>
                </v-row>
              </v-container>
              <div class="modal-banner ms-3 mt-5">
                <h2>Banner</h2>
                <p class="add">
                  Add your custom from
                  <span @click="bannerInput = true">here</span>
                </p>
                <v-row class="my-2">
                  <BannerItem
                    v-for="(banner, i) in banners"
                    :id="banner._id"
                    :key="i"
                    :banner="banner"
                    :index="i"
                    :ref="`banner-${i}`"
                    :class="{
                      'selected-banner': banner._id == schema.bannerId,
                    }"
                    class="banner-item"
                    @click.native="selectedBanner(`banner-${i}`, banner)"
                    @changeValue="changeValue"
                    @deleteBanner="showConfirmDeleteBannerDialog"
                  />
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    sm="6"
                    class="relative d-flex justify-end align-center py-1 cursor-pointer"
                  >
                    <v-text-field
                      v-if="bannerInput"
                      class="banner-class my-0"
                      v-model="bannerInputText"
                      label="Enter new banner"
                      solo
                      append-outer-icon="mdi-check-circle"
                      clear-icon=""
                      clearable
                      :hide-details="true"
                      type="text"
                      @click:append-outer="createBanner(bannerInputText)"
                      @keypress.enter="createBanner(bannerInputText)"
                      @click:clear="bannerInputText = ''"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-container>
            <v-container>
              <div v-if="isEligibleForOfferPage">
                <div class="my-6">
                  <h3 class="font-20">
                    Show In Offers Page?
                    <span
                      v-if="profile.plan.isFree"
                      class="cursor-pointer"
                      @click="
                        $store.dispatch('setProTipDialog', {
                          show: true,
                          title: 'Offers page',
                          description:
                            'Engage Customers Online with Our Digital Offers Page. Showcase your deals effectively in a vibrant, online format.',
                        })
                      "
                    >
                      <img
                        class="pro-flag ml-2"
                        width="20px"
                        height="20px"
                        :src="require('@/assets/images/icons/pro-flag.svg')"
                      />
                    </span>
                  </h3>
                  <span>
                    <v-switch
                      class="my-5 mr-0 ml-1"
                      :hide-details="true"
                      v-model="schema.isOffer"
                      inset
                    ></v-switch>
                  </span>
                </div>
              </div>
            </v-container>
          </v-tab-item>

          <!-- video -->
          <v-tab-item :value="2">
            <v-container class="">
              <v-row class="justify-space-between">
                <v-col cols="12" class="pa-6 d-flex input-container h-auto">
                  <input
                    id="video"
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    style="display: none"
                    @change="onFileChange"
                  />

                  <label for="video" class="cursor-pointer d-flex align-center">
                    <svg
                      height="20"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <title>video</title>
                      <path
                        d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z"
                      />
                    </svg>
                    <span class="ml-3">{{
                      schema.video
                        ? schema.video.title || schema.video.name
                        : "Upload Video File"
                    }}</span></label
                  >
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row>
                <v-col cols="12">
                  <h1 class="font-16">Video Show Type</h1>

                  <div class="d-flex gap-10">
                    <v-tooltip top color="white">
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          v-on="on"
                          v-bind="attrs"
                          width="35px"
                          height="35px"
                          :src="
                            schema.videoShowType === 'cover'
                              ? IconCoverSelected
                              : IconCoverUnselected
                          "
                          alt="cover"
                          class="cursor-pointer"
                          @click="selectShowType('cover', 'videoShowType')"
                        />
                      </template>
                      <span class="black--text font-16 d-inline-block mr-2">
                        cover
                      </span>
                    </v-tooltip>

                    <v-tooltip top color="white">
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          v-on="on"
                          v-bind="attrs"
                          width="35px"
                          height="35px"
                          :src="
                            schema.videoShowType === 'contain'
                              ? IconContainSelected
                              : IconContainUnselected
                          "
                          alt="contain"
                          class="cursor-pointer"
                          @click="selectShowType('contain', 'videoShowType')"
                        />
                      </template>
                      <span class="black--text font-16 d-inline-block mr-2">
                        contain
                      </span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row class="d-block">
                <v-col class="py-0 my-5 py-1 px-0">
                  <div class="w-fit-content timer px-1">
                    <img
                      class="cursor-pointer"
                      :src="require('@/assets/images/icons/timelapse 1.svg')"
                      alt=""
                    />
                    <span class="mx-3 w-fit-content">{{ counter }} sec</span>
                    <div>
                      <div
                        class="d-flex flex-column justify-center cursor-pointer align-center pusrple pa-0"
                      >
                        <v-icon class="pa-0" @click="incrementCounter"
                          >mdi-menu-up</v-icon
                        >
                        <v-icon class="pa-0" @click="decrementCounter"
                          >mdi-menu-down</v-icon
                        >
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row class="d-block">
                <v-col cols="12" class="px-0">
                  <v-btn
                    color="white"
                    class="more-details-btn no-box-shadow"
                    @click="showDetails = !showDetails"
                  >
                    <img
                      :src="require('@/assets/images/icons/more-details.svg')"
                      alt="More Details"
                    />
                    <span class="ml-2">More Details</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>

            <v-container v-if="showDetails" class="px-0">
              <v-container>
                <v-row class="justify-space-between">
                  <v-col
                    cols="12"
                    lg="7"
                    md="6"
                    sm="8"
                    class="pa-0 my-2 d-flex input-container"
                  >
                    <input
                      v-model="schema.title"
                      class="modal-input px-2 flex-grow-1"
                      type="text"
                      placeholder="Name"
                      @input="handleLength(60, 'title', 'titleLength')"
                    />
                    <span class="counter-validation">
                      60/
                      <span style="">
                        {{ titleLength }}
                      </span>
                    </span>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="2"
                    md="3"
                    sm="2"
                    class="pa-0 ma-2 d-flex input-container"
                  >
                    <span class="counter-validation currency-symbol">
                      {{ profile.currency ? profile.currency.symbol : "" }}
                    </span>
                    <input
                      v-model="schema.price"
                      class="modal-input px-2 flex-grow-1"
                      type="number"
                      placeholder="Price"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="2"
                    md="3"
                    sm="2"
                    class="pa-0 my-2 d-flex input-container"
                  >
                    <span class="counter-validation currency-symbol">
                      {{ profile.currency ? profile.currency.symbol : "" }}
                    </span>
                    <input
                      v-model="schema.oldPrice"
                      class="modal-input px-2 flex-grow-1"
                      type="number"
                      placeholder="Old Price"
                    />
                  </v-col>
                </v-row>
                <v-row class=" ">
                  <v-col
                    cols="12"
                    sm="12"
                    class="pa-0 my-2 d-flex input-container"
                  >
                    <input
                      @input="
                        handleLength(100, 'description', 'descriptionLength')
                      "
                      v-model="schema.description"
                      class="modal-input px-2 flex-grow-1"
                      type="text"
                      placeholder="Description"
                    />
                    <span class="counter-validation">
                      100/
                      <span style="">
                        {{ descriptionLength }}
                      </span>
                    </span>
                  </v-col>
                </v-row>
              </v-container>
              <div class="modal-banner mt-5 px-0">
                <h2>Banner</h2>
                <p class="add">
                  Add your custom from
                  <span @click="bannerInput = true">here</span>
                </p>
                <v-row class="my-2">
                  <BannerItem
                    v-for="(banner, i) in banners"
                    :id="banner._id"
                    :key="i"
                    :banner="banner"
                    :index="i"
                    :ref="`banner-${i}`"
                    :class="{
                      'selected-banner': banner._id == schema.bannerId,
                    }"
                    class="banner-item"
                    @click.native="selectedBanner(`banner-${i}`, banner)"
                    @changeValue="changeValue"
                    @deleteBanner="showConfirmDeleteBannerDialog"
                  />
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    sm="6"
                    class="relative d-flex justify-end align-center py-1 cursor-pointer"
                  >
                    <v-text-field
                      v-if="bannerInput"
                      class="banner-class my-0"
                      v-model="bannerInputText"
                      label="Enter new banner"
                      solo
                      append-outer-icon="mdi-check-circle"
                      clear-icon=""
                      clearable
                      :hide-details="true"
                      type="text"
                      @click:append-outer="createBanner(bannerInputText)"
                      @keypress.enter="createBanner(bannerInputText)"
                      @click:clear="bannerInputText = ''"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-container>
            <v-container>
              <div v-if="isEligibleForOfferPage">
                <div class="my-6">
                  <h3 class="font-20">
                    Show In Offers Page?
                    <span
                      v-if="profile.plan.isFree"
                      class="cursor-pointer"
                      @click="
                        $store.dispatch('setProTipDialog', {
                          show: true,
                          title: 'Offers page',
                          description:
                            'Engage Customers Online with Our Digital Offers Page. Showcase your deals effectively in a vibrant, online format.',
                        })
                      "
                    >
                      <img
                        class="pro-flag ml-2"
                        width="20px"
                        height="20px"
                        :src="require('@/assets/images/icons/pro-flag.svg')"
                      />
                    </span>
                  </h3>
                  <span>
                    <v-switch
                      class="my-5 mr-0 ml-1"
                      :hide-details="true"
                      v-model="schema.isOffer"
                      inset
                    ></v-switch>
                  </span>
                </div>
              </div>
            </v-container>
          </v-tab-item>
        </v-tabs-items>

        <div class="modal-actions d-flex flex-wrap px-0">
          <v-btn
            @click="cancelProduct"
            class="flex-grow-1 text-black"
            elevation="2"
            large
            :disabled="loading"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="saveProduct"
            class="flex-grow-1 primary"
            elevation="2"
            large
            :loading="loading"
            :disabled="loading"
          >
            Save

            <template v-slot:loader>
              <v-progress-circular indeterminate></v-progress-circular>
            </template>
          </v-btn>
        </div>
      </div>
    </div>

    <!-- Delete Modal -->
    <v-dialog
      v-model="confirmDeleteBannerDialog"
      max-width="400"
      content-class="white"
    >
      <custom-delete
        :delete-type="'confirm'"
        :description="'This banner will be deleted from all the products that are using it'"
        @close="confirmDeleteBannerDialog = false"
        @confirm="deleteBanner"
      ></custom-delete>
    </v-dialog>
  </div>
</template>

<script>
import NewUploadBtn from "@/components/base/NewUploadBtn.vue";
import IconCoverUnselected from "@/assets/images/icons/cover-unselected.svg";
import IconCoverSelected from "@/assets/images/icons/cover-selected.svg";
import IconContainUnselected from "@/assets/images/icons/contain-unselected.svg";
import IconContainSelected from "@/assets/images/icons/contain-selected.svg";
import BannerItem from "./BannerItem.vue";
import { mapGetters } from "vuex";
import { ValidationProvider, extend } from "vee-validate";

const validYtUrl = (url = "") => {
  var regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=|shorts\/)([^#&?]*).*/;

  var match = url.match(regExp);

  return !!(match && match[2].length == 11);
};

extend("yt_url", {
  validate: (value) => {
    return validYtUrl(value);
  },
  message: "Invalid Youtube URL",
});

export default {
  components: {
    NewUploadBtn,
    BannerItem,
    ValidationProvider,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: Object,
    },
    chosenGroup: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      counter: 5,
      loading: false,
      titleLength: 0,
      descriptionLength: 0,
      schema: {
        title: "",
        price: "",
        oldPrice: "",
        description: "",
        bannerId: "",
        featured: true,
        isOffer: true,
        active: true,
        show: "image",
        links: [
          {
            url: "",
            type: "youtube",
          },
        ],
        imageShowType: "cover",
        videoShowType: "cover",
        video: null,
      },
      myFiles: [],
      showOnHover: false,
      showForm: false,
      bannerInput: false,
      bannerInputText: "",
      bannerText: "",
      selectedBannerId: null,
      bannerArray: [],
      images: [],
      imageFormUpload: { imgDataUrl: null, url: null },
      selectedTab: 0,
      showDetails: false,
      IconCoverUnselected,
      IconCoverSelected,
      IconContainUnselected,
      IconContainSelected,
      confirmDeleteBannerDialog: false,
      bannerToDelete: "",
    };
  },
  computed: {
    ...mapGetters(["profile"]),
    banners() {
      return this.$store.getters["offersModule/banners"];
    },
    uploadProgress() {
      return this.$store.getters["offersModule/uploadProgress"];
    },
    isEligibleForOfferPage() {
      return this.images && this.schema.title && this.schema.price;
    },
  },
  mounted() {
    // //console.log("this.data props :>> ", this.data);
    //console.log("this.data :>> ", this.data);
    if (Object.keys(this.data).length) {
      this.bannerArray = this.banners;
      this.schema.title = this.data.title || "";
      this.schema.price = this.data.price || "";
      this.schema.oldPrice = this.data.oldPrice || "";
      this.schema.description = this.data.description || "";
      this.schema.featured = this.data.featured || false;
      this.schema.active = this.data.active || false;
      this.schema.isOffer = this.data.isOffer || false;
      this.schema.links = this.data.links || [
        {
          url: "",
          type: "youtube",
        },
      ];
      this.schema.video = this.data.video || null;
      this.schema.show = this.data.show || "image";
      this.imageFormUpload.url = this.data.images?.[0]?.url || "";
      this.schema.imageShowType = this.data.imageShowType;
      this.schema.videoShowType = this.data.video?.videoShowType;
      this.schema.bannerId = this.data.banner?.id || "";
      this.titleLength = this.schema.title.length;
      this.descriptionLength = this.schema.description.length;
      this.counter = this.data.counter ?? 1;

      switch (this.data.show) {
        case "youtube":
          this.selectedTab = 1;
          break;

        case "video":
          this.selectedTab = 2;
          break;

        default:
          this.selectedTab = 0;
          break;
      }
    }
  },
  methods: {
    onFileChange(e) {
      if (e.target && e.target.files) {
        this.schema.video = e.target.files[0];
      } else {
        this.schema.video = null;
      }
    },
    showProTip() {
      this.$store.dispatch("setProTipDialog", {
        show: true,
        title: "YouTube Video Link",
        description:
          "Bring YouTube Content to Your Displays. Easily add YouTube video links for engaging screen content.",
      });
    },
    selectShowType(type, itemShowType) {
      this.schema[itemShowType] = type;
    },
    incrementCounter() {
      this.counter++;
    },
    decrementCounter() {
      if (this.counter > 1) {
        this.counter--;
      }
    },
    handleLength(maxLength, text, length) {
      this.schema[text] = this.schema[text].slice(0, maxLength);
      //console.log("text.length :>> ", maxLength, text, length);
      this[length] = this.schema[text].length;
    },
    async createBanner(text) {
      this.bannerArray = [
        ...this.bannerArray,
        { text: "", id: "000000", add: true },
      ];
      await this.$store
        .dispatch("offersModule/createBanner", {
          text: text,
        })
        .then(() => {
          this.bannerInputText = null;
          // this.bannerText = null;
        })
        .catch((err) => {
          this.$store.dispatch("showSnack", {
            text: err.message,
            color: "error",
          });
        });
    },
    async saveProduct() {
      try {
        this.loading = true;
        if (this.selectedTab === 2) {
          this.$store.dispatch("showSnack", {
            text: `Uploading`,
            color: "success",
            timeout: 100000000,
          });
        }

        if (this.selectedTab === 0) {
          if (!this.imageFormUpload.url) {
            this.loading = false;
            throw new Error("Image is required");
          } else if (this.imageFormUpload.url.size > 1024 * 1024 * 10) {
            this.loading = false;
            throw new Error("Image size must be less than 10MB");
          }
        } else if (this.selectedTab === 1) {
          if (!this.schema.links[0].url) {
            this.loading = false;
            throw new Error("Video link is required");
          }
        } else if (this.selectedTab === 2) {
          if (!this.schema.video) {
            this.loading = false;
            throw new Error("Video file is required");
          }
        }

        const schema = this.schema;
        schema.isOffer = this.isEligibleForOfferPage ? schema.isOffer : false;
        schema.featured = this.schema?.featured || false;
        schema.active = this.schema?.active || false;
        schema.type = this.type;
        schema.images = this.imageFormUpload.url;
        schema.counter = this.counter;

        // if (this.bannerText) {
        //   schema.bannerText = this.bannerText;
        //   schema.bannerType = "save";
        // } else {
        //   schema.bannerText = null;
        //   schema.bannerType = null;
        // }

        if (this.schema.links.length && !this.schema.links[0].url) {
          schema.links = [];
        }

        if (this.selectedTab === 2) {
          schema.videoTitle = schema.video.name;
        }

        // if (schema.linkUrl) {
        //   schema.linkType = "youtube";
        // }

        if (this.isEdit) {
          this.updateProduct({ data: schema, id: this.data?._id });
        } else {
          this.createProduct({ data: schema });
        }
      } catch (err) {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: err.message,
          color: "error",
        });
      }
    },
    async createProduct({ data }) {
      //console.log("thi close dialog");
      data.group = this.chosenGroup;
      let formData = new FormData();

      for (let key in data) {
        if (data[key]) {
          if (
            typeof data[key] != "object" ||
            key == "images" ||
            key == "video"
          ) {
            formData.append(key, data[key]);
          } else {
            formData.append(key, JSON.stringify(data[key]));
          }
        }
      }

      try {
        this.$store
          .dispatch("offersModule/saveProduct", {
            groupId: this.chosenGroup,
            formData,
          })
          .then((res) => {
            this.loading = false;
            this.$store.dispatch("showSnack", {
              text: `Added Successfully`,
              color: "success",
            });
            this.showForm = false;
            this.$emit("closeModal");
          })
          .catch((e) => {
            this.loading = false;
            this.showForm = false;
            this.$emit("closeModal");
            console.error(e);
            this.$store.dispatch("showSnack", {
              text: e.message || e.error || "Something went wrong!",
              color: "error",
            });
          });
      } catch (e) {
        this.loading = false;
        this.showForm = false;
        this.$emit("closeModal");
        this.$store.dispatch("showSnack", {
          text: e ? e.message || e.error : "Something went wrong",
          color: "error",
        });
      }
    },
    async updateProduct({ data, id }) {
      let formData = new FormData();
      for (let key in data) {
        if (typeof data[key] != "object" || key == "images" || key == "video") {
          formData.append(key, data[key]);
        } else {
          formData.append(key, JSON.stringify(data[key]));
        }
      }

      this.$store
        .dispatch("offersModule/updateProduct", {
          formData,
          productId: id,
        })
        .then((res) => {
          this.loading = false;
          this.showForm = false;
          this.$emit("closeModal");
          this.$store.dispatch("showSnack", {
            text: "Edited Successfully",
            color: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
          this.showForm = false;
          this.$emit("closeModal");
          console.log({ err });
          this.$store.dispatch("showSnack", {
            text: err ? err.message || err.error : "Something went wrong",
            color: "error",
          });
        });
    },
    async cancelProduct() {
      this.resetData();
      this.$emit("closeModal");
    },
    async resetData() {
      this.schema = {
        title: "",
        price: "",
        oldPrice: "",
        description: "",
        bannerId: "",
      };
      // this.bannerText = "";
      this.imageFormUpload.url = null;
    },
    testClick() {
      //console.log("testClick :>> ");
    },
    // eslint-disable-next-line no-unused-vars
    selectedBanner(el, banner) {
      const refElement = this.$refs[el][0].$el;

      if (
        refElement.querySelector(".modal-input").classList.contains("disabled")
      ) {
        if (this.schema.bannerId == banner._id) {
          this.selectedBannerId = null;
          this.schema.bannerId = "";
        } else {
          this.selectedBannerId = banner._id;
          this.schema.bannerId = banner._id;
        }
      }
    },
    uploadImageHandler(url, file) {
      let fileCustom = new File([file], file.name.replace(/ /g, "_"), {
        type: file.type,
      });
      this.imageFormUpload.imgDataUrl = url;
      this.imageFormUpload.url = fileCustom;
    },
    async deleteImageHandler() {
      if (this.data?._id) {
        let formData = new FormData();
        formData.append("remove_images[0]", this.data.images[0]._id);
        await this.$axios.patch(
          `${process.env.VUE_APP_PRODUCTS}/${this.data._id}`,
          formData
        );
      }
    },
    deleteImageOnClient() {
      this.imageFormUpload.imgDataUrl = "";
      this.imageFormUpload.url = "";
    },
    showConfirmDeleteBannerDialog(id) {
      this.confirmDeleteBannerDialog = true;
      this.bannerToDelete = id;
    },
    async deleteBanner(id) {
      this.confirmDeleteBannerDialog = false;
      await this.$store.dispatch(
        "offersModule/deleteBanner",
        this.bannerToDelete
      );
      await this.$store.dispatch("offersModule/getAllData");
      const filter = this.bannerArray.filter(
        (x) => x._id != this.bannerToDelete
      );
      this.bannerArray = filter;
    },

    changeValue({ event, index, id }) {
      this.bannerArray.splice(index, 1);
      this.deleteBanner(id);
      this.bannerText = event.target.value;
      this.createBanner(id, event.target.value);
      console.log("changeValue: ", { event, index, id });
    },
  },
  watch: {
    // schema: {
    //   deep: true,
    //   handler(newVal) {},
    // },
    counter(newVal) {
      if (newVal) {
        //console.log("timer");
      }
    },
    selectedTab(val) {
      switch (val) {
        case 0:
          this.counter = 5;
          this.schema.show = "image";
          break;

        case 1:
          this.counter = 15;
          this.schema.show = "youtube";
          break;

        case 2:
          this.counter = 15;
          this.schema.show = "video";
          break;

        default:
          this.counter = 5;
          this.schema.show = "image";
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-modal {
  .counter-validation {
    font-size: 12px;
    text-align: right;
    display: block;
    border-radius: 10px;
    // position: absolute;
    // right: 12px;
    // top: 50%;
    // transform: translateY(-50%);
    background: #e3e3e3;
    width: fit-content;
    height: 100%;
    padding-inline: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .timer {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #ebebeb;
    //width: 30%;
  }
  .input-container {
    height: 44px;
    border-radius: 10px;
    background-color: #ebebeb;
    // overflow: hidden;

    &.h-auto {
      height: auto;
    }

    input {
      // padding-inline: 10px;
      &:focus {
        outline: none;
      }
    }

    .counter-validation {
      left: 12px;

      &.currency-symbol {
        // font-size: 1rem;
        font-weight: bold;
      }
    }
  }

  .modal-input {
    padding-block: 10px;
    width: 100%;
    outline: 0 none;
    border-radius: 10px;
  }

  .banner-item {
    border: 2px solid transparent;
  }
}
</style>

<style lang="scss">
.create-modal {
  .v-tabs {
    .v-slide-group__prev {
      display: none !important;
    }

    .v-tabs-bar {
      background-color: transparent;

      .v-tab {
        background-color: white;
        color: black;
        text-transform: unset;
        border: 1px solid #d7d7d7;

        img {
          margin-right: 5px;
        }

        @media (max-width: $breakpoint-md) {
          font-size: 16px;

          img {
            height: 15px;
          }
        }

        &--active {
          background-color: white;
          color: black;
          border-color: $primary;
        }
      }
    }
  }

  .v-tabs-items {
    background-color: transparent;
  }

  .more-details-btn {
    padding-block: 10px !important;
    border: 1px solid #d7d7d7 !important;
    border-radius: 10px;
    background-color: white;
    color: black;
    height: unset !important;
  }
}
</style>
