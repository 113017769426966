<!-- eslint-disable vue/no-unused-components -->
<template>
  <div>
    <v-expansion-panels v-if="groupItems.length" class="groups-tabs w-100" multiple>
      <PanelTab
        class="flex-column"
        :atDeletedSide="atDeletedSide"
        :type="type"
        v-for="(group, index) in groupItems"
        :key="index"
        :group="group"
        :index="index"
        :isTrash="isTrash"
        @openThemeSelector="selectThemeHandler"
        @openColorPicker="openColorPicker"
        @openSelectScreen="toggleSelectScreen($event, true)"
      />
    </v-expansion-panels>

    <h1 v-else class="font-20">Start adding your groups here...</h1>

    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <custom-delete
        @close="deleteDialog = false"
        @getData="$emit('updateData')"
        :urlApi="urlApi"
        :itemID="selectedItem._id"
      ></custom-delete>
    </v-dialog>

    <!-- add/edit new -->
    <v-dialog v-model="categoryDialog" max-width="450px">
      <new-category
        @close="categoryDialog = false"
        @update="$emit('updateData')"
        :isEdit="true"
        :item="selectedItem"
        :key="categoryDialog"
      ></new-category>
    </v-dialog>

    <!-- assign screen to group -->
    <v-dialog
      v-model="selectScreensDialog"
      max-width="450px"
      content-class="white"
    >
      <select-screens
        @close="selectScreensDialog = false"
        :groups="groupItems"
        :current-group="selectedItem"
      ></select-screens>
    </v-dialog>

    <!-- add/edit new product -->
    <v-dialog
      v-model="newProductDialog"
      max-width="530px"
      content-class="white "
      :key="newProductDialog"
    >
      <new-product
        @close="newProductDialog = false"
        :isEdit="isEditProduct"
        :key="keyProductDialog"
        :selectedItem="selectedItem"
        @update="[$emit('updateData'), (newProductDialog = false)]"
        :chosedGroup="chosedGroup"
      ></new-product>
    </v-dialog>

    <!-- select theme -->
    <v-dialog
      v-model="selectThemeDialog"
      content-class="select-theme-dialog border-radius-0"
      :key="selectThemeDialog + Math.random() * 1000"
      location="right"
    >
      <select-theme
        @close="selectThemeDialog = false"
        v-if="selectThemeDialog"
        :item="selectedItem"
        :key="selectThemeDialog"
        @update="updateHandler"
        :isTemplate="type == 'template'"
      ></select-theme>
    </v-dialog>

    <v-dialog
      v-model="colorPickerDialog"
      max-width="530px"
      content-class="white"
      :key="colorPickerDialog + Math.random() * 1000"
    >
      <color-picker
        @close="closeColorPicker"
        :current-group="selectedItem"
      ></color-picker>
    </v-dialog>
  </div>
</template>

<script>
import NewCategory from "./NewCategory.vue";
import NewProduct from "@/components/dialogs/NewProduct.vue";
import SelectTheme from "../../../components/dialogs/SelectTheme.vue";
import PanelTab from "./PanelTab.vue";
import ColorPicker from "@/components/dialogs/ColorPicker.vue";
import SelectScreens from "./SelectScreens.vue";

export default {
  components: {
    PanelTab,
    NewCategory,
    SelectTheme,
    NewProduct,
    ColorPicker,
    SelectScreens,
  },
  props: {
    atDeletedSide: {
      type: Boolean,
      default: false,
    },
    isTrash: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "item",
    },
  },
  data() {
    return {
      showForm: false,
      isOpen: false,
      panel: [],
      items: 1,
      itemSelectedKey: false,
      selectedItem: {},
      selectThemeDialog: false,
      categoryDialog: false,
      chosedGroup: false,
      newProductDialog: false,
      isEditProduct: false,
      deleteDialog: false,
      urlApi: false,
      keyProductDialog: false,
      colorPickerDialog: false,
      selectScreensDialog: false,
    };
  },
  computed: {
    themes() {
      return this.$store.getters["themes"];
    },
    groupItems() {
      return this.$store.getters["offersModule/allData"];
    },
  },
  methods: {
    saveData() {
      this.showForm = true;
    },
    togglePanel($event) {
      //console.log("$event", $event);
    },
    handleClick() {
      // //console.log("handleClick");
      this.isOpen = !this.isOpen;
    },
    expansionChanged(state) {
      alert(state);
    },
    isTapsOpen(exist) {
      if (exist) {
        this.isOpen = !exist;
      }
      // const is = document.querySelector(".single-tab");
      // if (is) {
      //   const test = is.getAttribute("aria-expanded");
      //   // this.isOpen = is == "true" ? true : false;
      //   // //console.log("object", test);
      //   if (test == "true") {
      //     // //console.log("object");
      //     this.isOpen = true;
      //   } else {
      //     this.isOpen = false;
      //   }
      // }
    },
    checkMove: function (e) {
      //console.log("checkMove");
      // return false if not the context
      return e.relatedContext.element._id == e.relatedContext.element._id;
    },
    // editHandlerItem(item) {
    //   this.selectedItem = {};
    //   this.isEditProduct = true;
    //   this.newProductDialog = true;
    //   this.keyProductDialog = !this.keyProductDialog;
    //   this.selectedItem = { ...item };
    // },
    restorGetHandler() {
      this.$emit("updateData");
    },
    async orderChangeHandler(group, idx) {
      let newProducts = this.groupItems[idx].products.map(
        (item) => item.item._id
      );
      let sortObj = {
        groupId: group._id,
        products: newProducts,
      };
      await this.$axios.post(`/products/group/sort`, sortObj);
    },
    checkDate(date) {
      if (date) {
        let itemDate = date.slice(0, 10);
        let today = new Date().toISOString();
        let todayTime = today.slice(0, 10);
        if (itemDate > todayTime) {
          return "";
        } else {
          return " filter-grey";
        }
      } else {
        return " filter-grey";
      }
    },
    toggleGroup(i) {
      this.groupItems.forEach((item, idx) => {
        if (idx == i) {
          item.open = !item.open;
        }
      });
    },
    selectThemeHandler(item) {
      this.selectThemeDialog = true;
      this.selectedItem = item;
    },
    deletePermanentHandler(item) {
      this.deleteDialog = true;
      //       //console.log(item);
      this.urlApi = process.env.VUE_APP_GROUPS;
      this.selectedItem = item;
    },
    async restoreHandler(item) {
      let response = await this.$axios.post(
        `${process.env.VUE_APP_GROUPS}/${item._id}/status`,
        {
          status: "restored",
        }
      );
      //console.log(response);
      if (response.status == 200) {
        this.$emit("updateData");
      }
    },
    editHandler(item) {
      this.categoryDialog = true;
      this.selectedItem = item;
    },

    addNewHandler(group) {
      // this.$refs[`arrow-${i}`][0].$el.click();
      // //console.log("ref", this.$refs.arrow[0].$el);
      this.chosedGroup = group;
      // this.newProductDialog = true;
      this.isEditProduct = false;
      this.selectedItem = {};
    },
    deleteHandler(item) {
      this.deleteDialog = true;
      this.urlApi = process.env.VUE_APP_GROUPS;
      this.selectedItem = item;
    },
    updateHandler() {
      this.selectThemeDialog = false;
      this.$emit("updateData");
    },
    openColorPicker(group) {
      this.colorPickerDialog = true;
      this.selectedItem = group;
    },
    closeColorPicker() {
      this.colorPickerDialog = false;
      this.selectedItem = {};
    },
    toggleSelectScreen(group, value = undefined) {
      this.selectScreensDialog =
        value === undefined ? !this.selectScreensDialog : value;
      this.selectedItem = group;
    },
  },
};
</script>

<style lang="scss">
.v-dialog.select-theme-dialog {
  max-height: 100%;
  width: 100%;
  margin: 0;
}
</style>
