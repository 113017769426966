<template>
  <div class="new-item pa-3">
    <div class="new-item__content">
      <!-- desktop head -->

      <div class="w-100 mb-3 d-flex align-center justify-space-between px-3">
        <div
          class="max-width w-100"
          :class="isMobile ? 'text-center' : ' text-left'"
        >
          <span class="black--text font-24 font-500"
            >{{ isEdit ? selectedItem.title : "New Product" }}
          </span>
        </div>
        <img
          width="15px"
          class="cursor-pointer"
          @click="$emit('close')"
          :src="require('@/assets/images/icons/close.svg')"
        />
      </div>
      <v-form v-model="valid" ref="productForm">
        <v-row>
          <!-- content -->
          <v-col cols="12">
            <v-row>
              <template v-for="(image, i) in images">
                <v-col
                  v-if="i == 0"
                  md="12"
                  class="px-0 pb-0"
                  :key="fileKey + i"
                >
                  <div class="pa-3 relative cursor-pointer">
                    <img
                      v-if="imageFormUpload.imgDataUrl || imageFormUpload.url"
                      width="15px"
                      class="upload-file-close"
                      @click.stop="
                        [deleteImageOnClient(), deleteImageHandler()]
                      "
                      :src="require('@/assets/images/icons/close.svg')"
                    />
                    <img
                      v-if="imageFormUpload.imgDataUrl || imageFormUpload.url"
                      width="100%"
                      height="100%"
                      :src="imageFormUpload.imgDataUrl || image.url"
                      alt=""
                      class="object-contain"
                    />
                    <NewUploadBtn
                      v-else
                      text="Logo image"
                      class="upload-logo w-100"
                      @imagePicked="uploadImageHandler"
                    >
                    </NewUploadBtn>
                  </div>
                </v-col>

                <!-- other images -->
                <!-- <v-col v-if="i > 0" cols="3" class="pt-0" :key="fileKey + i">
                  <div class="d-flex">
                    <div class="w-100">
                      <upload-btn class="upload-logo upload-file-mini" @click="uploadImageHandler(image, i)"
                        @deleteFile="deleteFile(i, image._id)" text="Logo image" :image64="image.imgDataUrl || image.url"
                        :image="image" :cropImage="true"></upload-btn>
                    </div>
                  </div>
                </v-col> -->
              </template>
            </v-row>
          </v-col>

          <!-- title -->
          <v-col cols="12" class="px-10">
            <v-text-field
              v-model="product.title"
              label="Title"
              dense
              :hide-details="true"
            ></v-text-field>
          </v-col>

          <!-- price -->
          <v-col cols="12" md="6" class="pl-10 pb-0">
            <v-text-field
              v-model.number="product.price"
              label="Price"
              dense
              outlined
              rounded
              :hide-details="true"
            ></v-text-field>
          </v-col>

          <!-- discount -->
          <v-col cols="12" md="6" class="pr-10 pb-0">
            <v-text-field
              v-model="product.oldPrice"
              label="Discount"
              dense
              rounded
              outlined
              :hide-details="true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="px-10 py-0">
            <span class="font-16 grey--text"
              >Currency type: $.
              <span
                class="black--text font-600 cursor-pointer"
                @click="currencyDialog = true"
                >Click to change</span
              ></span
            >
          </v-col>

          <!-- banner -->
          <v-col cols="12" class="px-10 pb-0">
            <span class="font-600 black--text d-block">Banner:</span>
            <span class="grey--text d-block font-12"
              >add your custome from
              <span
                @click="bannerValue = 'Custom Banner'"
                class="font-600 cursor-pointer"
                >here</span
              ></span
            >
            <div
              v-for="(banner, i) in banners"
              :key="i"
              class="d-flex align-center justify-space-between"
            >
              <v-checkbox
                v-model="bannerValueText"
                :label="banner.text"
                :value="banner.text"
              ></v-checkbox>
              <v-icon color="error" @click="deleteHandler(banner._id)"
                >mdi-delete</v-icon
              >
            </div>
            <div class="px-4">
              <v-text-field
                v-if="bannerValue == 'Custom Banner'"
                v-model="bannerText"
                append-outer-icon="mdi-send"
                filled
                clear-icon="mdi-close-circle"
                clearable
                dense
                :hide-details="true"
                label="Enter new banner"
                type="text"
                @click:append-outer="sendBannerHandler()"
                @keypress.enter="sendBannerHandler()"
                @click:clear="
                  (bannerText = ''), (bannerValue = 'Custom Banner')
                "
              ></v-text-field>
            </div>
          </v-col>

          <!-- Description -->
          <v-col cols="12" class="px-10 pt-0">
            <v-textarea
              v-model="product.description"
              label="Description"
              dense
              rows="2"
              :hide-details="true"
            ></v-textarea>
          </v-col>

          <!-- feature -->
          <v-col cols="12" md="6" class="px-10">
            <span class="d-block font-18">Featured</span>
            <v-switch
              class="mt-0 mr-0 custom-switch"
              :hide-details="true"
              v-model="product.featured"
              inset
            ></v-switch>
          </v-col>

          <v-col cols="12" class="px-10">
            <v-btn
              width="100%"
              height="40px"
              color="primary"
              :loading="loading"
              :disabled="loading"
              @click="addItem"
            >
              <span class="font-18 font-500 white--text">Done</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <!-- <v-dialog
      v-model="uploadImage"
      content-class="no-shadow white-bg radius-15 relative upload-dialog"
    >
      <my-upload
        :key="uploadImage"
        field="img"
        @crop-success="cropSuccess"
        :width="300"
        :height="300"
        img-format="png"
        langType="en"
        :noCircle="true"
      ></my-upload>
      <img
        width="15px"
        height="15px"
        :src="require('@/assets/images/icons/close.svg')"
        class="upload-cancel cursor-pointer"
        @click="uploadImage = false"
      />
    </v-dialog> -->

    <!-- currency dialog -->
    <v-dialog
      v-model="currencyDialog"
      max-width="400"
      content-class=" radius-25"
    >
      <currency-dialog
        :item="profile"
        @close="currencyDialog = false"
      ></currency-dialog>
    </v-dialog>
  </div>
</template>

<script>
// import LongText from "../../components/base/LongText.vue";
import CurrencyDialog from "@/components/dialogs/CurrencyDialog.vue";
// import UploadBtn from "../../components/base/UploadBtn.vue";
import myUpload from "vue-image-crop-upload";
import NewUploadBtn from "@/components/base/NewUploadBtn.vue";
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    selectedItem: {
      type: Object,
      default: Object,
    },
    chosedGroup: {
      type: Object,
      default: () => [Object, String],
    },
  },
  components: {
    // UploadBtn,
    // eslint-disable-next-line vue/no-unused-components
    "my-upload": myUpload,
    CurrencyDialog,
    // eslint-disable-next-line vue/no-unused-components
    NewUploadBtn,
  },
  data: () => ({
    valid: false,
    loading: false,
    currencyDialog: false,
    bannerDialog: false,
    file: {},
    fileKey: false,
    product: { images: [{ image: {} }] },
    text: "Item Description",
    imageSizeLargeThanOneMb: false,
    uploadImage: false,
    imgDataUrl: "",
    banners: [],

    images: [{ image: {}, imgDataUrl: "" }],
    selectedIndex: 0,
    isEditImage: false,
    bannerValue: "",
    bannerText: "",
    imageFormUpload: {
      imgDataUrl: "",
      url: null,
    },
    bannerValueText: "",
  }),

  methods: {
    async sendBannerHandler() {
      //console.log("sendBannerHandler", this.bannerText);
      let res = await this.$axios.post(process.env.VUE_APP_BANNERS, {
        text: this.bannerText,
      });
      if (res.data) {
        this.bannerText = "";
        this.bannerValue = "";
        await this.getBanners();
      }
    },
    async deleteHandler(id) {
      let res = await this.$axios.delete(
        `${process.env.VUE_APP_BANNERS}/${id}`
      );
      if (res.data) {
        this.bannerText = "";
        this.bannerValue = "";
        await this.getBanners();
      }
    },
    deleteFile(event, id) {
      this.images[event] = {};
      this.deleteImageHandler(id);
      this.fileKey = !this.fileKey;
    },
    async addItem() {
      this.loading = true;

      let formData = new FormData();
      if (this.imageSizeLargeThanOneMb) {
        throw new Error("Image size is larger than 1MB");
      } else {
        formData.append("images", this.imageFormUpload.url);
      }
      // check validate
      let valid = this.$refs.productForm.validate();
      if (!valid) return;
      let res;
      if (this.isEdit) {
        this.$store
          .dispatch("offersModule/updateProduct", {
            formData,
            productId: this.product._id,
          })
          .catch((err) => {
            this.$store.dispatch("showSnack", {
              text: err.message || err.error || "Something went wrong",
              color: "error",
            });
          });
      } else {
        res = await this.$store.dispatch("offersModule/saveProduct", formData);
        // //console.log("res res", res);
        // this.$store.commit("SET_PRODUCT_NUMBER", res.planDetails);
      }
      if (res.data) {
        this.loading = false;

        this.$store.dispatch("showSnack", {
          text: `${this.isEdit ? "Edited" : "Added "} Successfully`,
          color: "success",
        });
        this.$emit("close");
        // this.$emit("update");
      } else {
        this.loading = false;

        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    async deleteImageHandler() {
      if (this.product._id) {
        let formData = new FormData();

        if (this.isEdit) {
          formData.append("remove_images[0]", this.product.images[0]._id);
        }
        if (this.isEdit) {
          await this.$axios.put(
            `${process.env.VUE_APP_PRODUCTS}/${this.product._id}`,
            formData
          );
        }
      }
    },
    // uploadImageHandler(image, i) {
    //   this.selectedIndex = i;
    //   if (image.imgDataUrl || image.url) {
    //     this.isEditImage = true;
    //   } else {
    //     this.isEditImage = false;
    //   }
    //   this.uploadImage = true;
    // },
    async cropSuccess(imgDataUrl) {
      if (this.images.length < 6) {
        this.images[this.selectedIndex].imgDataUrl = imgDataUrl;
        this.uploadImage = false;
        this.images[this.selectedIndex].image.file = this.dataURLtoFile(
          imgDataUrl,
          "image.png"
        );

        if (this.images.length < 5) {
          this.images.push({ image: {} });
        }
      }
    },
    uploadImageHandler(imageUrl, imageData, size) {
      this.imageFormUpload.imgDataUrl = imageUrl;
      this.imageFormUpload.url = imageData;
      this.imageSizeLargeThanOneMb = size >= 1;
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    async getBanners() {
      let { data } = await this.$axios.get(process.env.VUE_APP_BANNERS);
      if (data) {
        this.banners = data.data;
      }
    },
    deleteImageOnClient() {
      //console.log("delete");
      this.imageFormUpload.imgDataUrl = "";
      this.imageFormUpload.url = "";
    },
  },
  async created() {
    this.product = { images: [{ image: {} }] };
    this.images = [{ image: {}, imgDataUrl: "" }];

    await this.getBanners();
    if (this.isEdit) {
      this.product = this.selectedItem;
      this.imageFormUpload.url = this.product.images[0].url;
      this.images = this.product.images.filter((img) => img._id);
      if (this.images.length <= 4) {
        this.images.push({ image: {} });
      }
      this.bannerValueText = this.product.banner.text;
    }
  },
};
</script>

<style lang="scss">
.new-item {
  .upload-file-close {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 9;
  }

  .upload-logo {
    width: 100% !important;
    height: 250px;
  }

  .upload-logo-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .upload-file-mini {
    height: 100px !important;
  }

  &__preview {
    background: #f0f0f0;
    border-radius: 25px;
    height: 320px;
    max-height: 320px;
    width: 320px;
    height: max-content;
    padding: 23px 38px;
  }

  &__price {
    border-left: 1px solid #cecccc;
    padding-left: 20px;

    &__discount {
      position: relative;
      width: max-content;

      &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 1px;
        transform: rotate(21deg);
        background: red;
        top: 50%;
      }
    }
  }

  .v-list {
    background: white;
  }

  @media (max-width: 960px) {
    &__content {
      padding: 0px 40px;
    }
  }
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #c3c3c3;
}
</style>
