<template>
  <div class="pa-4 select-screens" :key="key">
    <div>
      <header class="mb-10 text-center position-relative">
        <img
          width="10px"
          class="cursor-pointer close-btn"
          :src="require('@/assets/images/icons/close.svg')"
          @click="$emit('close')"
        />

        <h1 class="font-18 font-500">Assign to screens</h1>
      </header>

      <!-- templates -->
      <div class="mt-3">
        <div v-if="screens.length" class="px-4 custom-group over-scroll">
          <v-btn
            class="screen w-100 no-box-shadow d-flex align-center justify-space-between px-3 py-2 mb-2"
            v-for="(screen, i) in screens"
            :key="i"
            :class="{ selected: screen.selected }"
            @click="selectScreen(screen)"
          >
            <span class="font-18 font-600 group-name w-100 text-center"
              >{{ screen.title }}
            </span>
          </v-btn>
        </div>
        <span v-else class="font-18 font-600 w-100 text-center d-inline-block"
          >No screens added yet!</span
        >
      </div>

      <v-btn
        block
        height="40px"
        color="primary"
        class="radius-10 mt-5"
        @click="submitHandler"
        elevation="0"
        :disabled="loading || !screens.length"
        :loading="loading"
      >
        <span class="font-18 font-500 white--text ml-2">Done</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    currentGroup: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    itemsGroups: [],
    templatesGroups: [],
    screens: [],
    loading: false,
    key: Math.random(),
  }),
  computed: {
    ...mapGetters(["themes"]),
  },
  methods: {
    selectScreen(selectedScreen) {
      this.screens.forEach((screen) => {
        if (selectedScreen._id == screen._id) {
          screen.selected = !screen.selected;
        }
      });
    },
    submitHandler() {
      this.loading = true;
      this.$axios
        .post(`${process.env.VUE_APP_PREVIEWS}/bulk/groups`, {
          groupId: this.currentGroup._id,
          previewsIds: this.screens
            .filter((screen) => screen.selected)
            .map((screen) => screen._id),
        })
        .then((res) => {
          this.loading = false;
          this.$store.dispatch("showSnack", {
            text: "Done",
            color: "success",
          });

          this.$emit("close");
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch("showSnack", {
            text: err.message || "Something went wrong",
            color: "error",
          });
        });
    },
  },
  async mounted() {
    console.log("mounted");
    this.itemsGroups = [...this.groups];
    //console.log("templatesGroups", this.templatesGroups);

    this.$axios.get(process.env.VUE_APP_PREVIEWS).then((res) => {
      this.screens = res.data.data.map((screen) => {
        return {
          selected: this.currentGroup.previewsIds.includes(screen._id),
          ...screen,
        };
      });
    });
  },
  watch: {
    currentGroup(newVal) {
      this.screens.forEach((screen) => {
        screen.selected = newVal.previewsIds.includes(screen._id);
        return {
          ...screen,
        };
      });
    },
  },
};
</script>

<style lang="scss">
.theme-name {
  background: rgb(1 149 169 / 20%);
  border-radius: 10px;
  padding: 7px 10px;
  margin: 0 5px;
  font-weight: 500;
}
.select-screens {
  .close-btn {
    position: absolute;
    right: 0;
    // top: 50%;
    // transform: translateY(-50%);
  }

  .custom-group {
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;

    .group-name {
      line-height: 1.8;
    }

    .screen {
      border-radius: 11px;
      border: 1px solid transparent;

      &.selected {
        border-color: #0195a9;
      }
    }
  }
}
</style>
