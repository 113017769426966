<template>
  <div>
    <div
      class="d-flex align-center justify-space-between px-10 py-3 darkbg-grey"
    >
      <span class="font-22">Assign Item To Group</span>
      <img
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
        class="cursor-pointer"
      />
    </div>
    <div class="bg-grey px-10 py-6">
      <v-form v-model="valid" ref="groupForm" @submit.prevent="submitHandler">
        <base-select
          v-model="newGroup"
          :items="groups"
          placeholder="Groups"
          :hide-details="true"
          class="mt-4"
          itemText="name"
          :groups="groups"
        >
        </base-select>

        <v-btn
          class="mt-6 radius-15"
          height="45px"
          color="primary"
          elevation="0"
          block
          type="submit"
          ><span class="white--text font-16">Assign</span></v-btn
        >
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    newGroup: "",
    valid: false,
  }),
  methods: {
    async submitHandler() {
      let valid = this.$refs.groupForm.validate();
      if (!valid) {
        return;
      }
      let res;
      if (this.items.length > 1) {
        let products = [];
        this.items.forEach((item) => {
          products.push(item._id);
        });
        // TODO: CHECK WITH BACKEND
        res = await this.$axios.post(`"/products/assign"`, {
          products,
          group: this.newGroup,
        });
      } else {
        let formData = new FormData();
        formData.append("group", this.newGroup);
        res = await this.$axios.patch(
          `${process.env.VUE_APP_PRODUCTS}/${this.items[0]._id}`,
          formData
        );
      }
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: "Updated Successfully",
          color: "success",
        });
        this.$emit("update");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    async getGroups() {
      let { data } = await this.$axios.get(`${process.env.VUE_APP_GROUPS}?acceptempty=true`);
      this.products = data.products;
    },
  },
  async created() {
    // await this.getProducts();
  },
};
</script>

<style lang="scss" scoped>
.item-counts {
  span {
    padding: 5px 10px;
    background: #d2e8e3;
    border-radius: 16px;
    transition: 0.3s;
  }
}
.theme-active {
  background: $primary !important;
  color: white;
}
</style>
